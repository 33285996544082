import {User} from "../../interfaces/user_interface";
import React from "react";
import {StructureApi} from "../../api/structures_api";
import {
    Box, Button, Divider, List, ListItem,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import {UserApi} from "../../api/users_api";
import {GeoRestrictionApi} from "../../api/geo_restrictions_api";
import Icon from "@mdi/react";
import {mdiLogout} from "@mdi/js";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";
import AppHeader from "../../components/common/AppHeader";

export interface ProfilsProps {
    current_user: number
}

interface ProfilState {
    structures: any[] // TODO : corriger le typage
    user: User
    zones: any[]
    action_user_id: number
    delete: boolean
}

export class Profil extends React.Component<ProfilsProps, ProfilState> {
    constructor(props: ProfilsProps) {
        super(props)
        this.state = {
            user: {
                id: 0,
                firstname: '',
                lastname: '',
                email: '',
                maillingAddress: '',
                phoneNumber: '',
                isAdmin: false,
                isBetaTest: false,
                isCnpf: false,
                isActive: true,
                numDevicesAuthorized: 0,
                structureRole: 'UNIQUE',
                expireOn: new Date().getTime() / 1000,
                structureId: 1,
                devices: [],
            },
            structures: [],
            zones: [],
            action_user_id: NaN,
            delete: false
        }
        this.getStructures = this.getStructures.bind(this)
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            const user = await UserApi.getUserById(this.props.current_user)
            const structures = await this.getStructures()
            const zoneList = await GeoRestrictionApi.listGeoRestrictions()
            this.setState({
                user: user,
                structures: structures,
                zones: zoneList
            })
        } catch (e) {
            console.error(e)
            alert("Echec du chargement de l'utilisateur")
        }
    }

    async getStructures() {
        const structureList = await StructureApi.listStructures()
        return structureList.map((structure: any) => {
            return {name: structure.name, value: structure.id}
        })
    }

    render() {
        return (
            <Box sx={{p: 4}}>
                <AppHeader title="Mon profil" />
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Box sx={{
                            p: 2, pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <Box sx={{pb: 2, pt: 2}}>
                                <h3>Informations générales</h3>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>Nom
                                    </Typography>
                                </Box>
                                <Box sx={{flexBasis: '80%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{this.state.user.lastname.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider/>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>Prénom
                                    </Typography>
                                </Box>
                                <Box sx={{flexBasis: '80%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{this.state.user.firstname}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider/>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>Email:
                                    </Typography>
                                </Box>
                                <Box sx={{flexBasis: '80%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{this.state.user.email}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider/>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>N° de Téléphone</Typography>
                                </Box>

                                <Box sx={{flexBasis: '80%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{this.state.user.phoneNumber}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider/>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>Adresse Postale</Typography>
                                </Box>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{this.state.user.maillingAddress}</Typography>
                                </Box>
                            </Box>
                            <Divider/>

                            {this.state.user.isCnpf ? (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Membre CNPF</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Oui</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>

                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Membre CNPF</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Non</Typography>
                                    </Box>
                                </Box>
                            )}
                            <Divider/>

                            {this.state.user.isActive ? (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Compte Actif</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Oui</Typography>
                                    </Box>
                                </Box>

                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Compte Actif</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Non</Typography>
                                    </Box>
                                </Box>
                            )}
                            <Divider/>

                            {this.state.user.isBetaTest ? (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Compte Beta-Test</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Oui</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Compte Beta-Test</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Non</Typography>
                                    </Box>
                                </Box>
                            )}
                            <Divider/>

                            {this.state.user.isAdmin ? (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Rôle:</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Administrateur</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 2,
                                    mb: 2
                                }}>
                                    <Box sx={{flexBasis: '20%'}}>
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            color: 'text.secondary'
                                        }}>Rôle:</Typography>
                                    </Box>
                                    <Box sx={{flexBasis: '80%'}}>
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>Utilisateur</Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            maxWidth: {xs: '100%', sm: '800px'},
                        }}
                    >
                        <Box sx={{
                            p: 2, pt: 1,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                            borderRadius: 2
                        }}>
                            <Box sx={{pb: 2, pt: 2}}>
                                <h3>Licence</h3>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>Date d'expiration</Typography>
                                </Box>
                                <Box sx={{flexBasis: '80%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{new Date(this.state.user.expireOn * 1000).toLocaleDateString()}</Typography>
                                </Box>
                            </Box>
                            <Divider/>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>Nombre d'appareils autorisés</Typography>
                                </Box>
                                <Box sx={{flexBasis: '80%'}}>
                                    {(this.state.user.devices?.length && (this.state.user.devices?.length > this.state.user.numDevicesAuthorized)) ? (
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                            color: 'red'
                                        }}>{this.state.user.numDevicesAuthorized}
                                        </Typography>
                                    ) : (
                                        <Typography sx={{
                                            fontWeight: '400',
                                            fontSize: '0.9rem',
                                        }}>
                                            {this.state.user.numDevicesAuthorized}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Divider/>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 2,
                                mb: 2
                            }}>
                                <Box sx={{flexBasis: '20%'}}>
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.8rem',
                                        color: 'text.secondary'
                                    }}>
                                        Nombre d'appareils connectés
                                    </Typography>
                                </Box>
                                <Box sx={{flexBasis: '80%'}}>
                                    <Typography sx={{
                                        fontWeight: '400',
                                        fontSize: '0.9rem',
                                    }}>{this.state.user.devices?.length}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <TableContainer component={Paper} sx={{marginTop: 5}}>
                                    <Table>
                                        <TableHead sx={{backdropFilter: 2, backgroundColor: 'primary.main',}}>
                                            <TableRow>
                                                <TableCell sx={{color: '#ffffff'}}>
                                                    Nom de l'appareil
                                                </TableCell>
                                                <TableCell sx={{color: '#ffffff'}}>
                                                    Dernière utilisation
                                                </TableCell>
                                                <TableCell sx={{color: '#ffffff'}}>
                                                    Déconnexion
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!(this.state.user.devices?.length) ? (
                                                <TableRow sx={{
                                                    color: '#ffffff'
                                                }}>
                                                    <TableCell sx={{py: 2}}>
                                                        Aucun appareil connecté à cet utilisateur
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.user.devices
                                                    .sort((a, b) => a.lastUsed < b.lastUsed ? 1 : -1)
                                                    .map((device: any) => (
                                                        <TableRow
                                                            key={device.id}
                                                            sx={{
                                                                '&:hover': {
                                                                    backgroundColor: 'background.default',
                                                                },
                                                            }}
                                                        >
                                                            <TableCell sx={{py: 2}}>
                                                                {device.name}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {new Date(
                                                                    device.lastUsed * 1000
                                                                ).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                <Box>
                                                                    <List>
                                                                        <ListItem sx={{p: 0, my: 1}}>
                                                                            <Tooltip
                                                                                title="Déconnecter"
                                                                                placement="right"
                                                                            >
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            action_user_id:
                                                                                            device.id,
                                                                                            delete: true,
                                                                                        })
                                                                                    }}
                                                                                    sx={{p: 0, px: 1}}
                                                                                    color="error"
                                                                                >
                                                                                    <Icon
                                                                                        path={mdiLogout}
                                                                                    />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </ListItem>
                                                                    </List>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {this.state.delete ? (
                                    <Areyousure
                                        message="supprimer un appareil"
                                        action={async () => {
                                            // TODO : déplacer une méthode de classe et gérer les erreurs
                                            await UserApi.deleteUserDevice(this.state.action_user_id)
                                            window.location.reload();
                                        }}
                                        close={() => {
                                            this.setState({delete: false})
                                        }}
                                        reload={() => {}}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Box>
                    </Box>


                    {this.state.zones?.length ? (
                        <Box

                            sx={{
                                p: 1,
                                width: '100%',
                                maxWidth: {xs: '100%', sm: '800px'},
                            }}
                        >
                            <Box sx={{
                                p: 2, pt: 1,
                                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 0.5px",
                                borderRadius: 2
                            }}>
                                <Box sx={{pb: 2, pt: 2}}>
                                    <h3>Zones autorisées</h3>
                                </Box>
                                <Box>
                                    {this.state.zones.map((zone: any) => (
                                        <Box sx={{mt: 1}} key={zone.id}>
                                            <Typography sx={{
                                                fontWeight: '400',
                                                fontSize: '0.9rem',
                                            }}>
                                                {zone.name}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>) : (<></>)}
                </Box>
            </Box>
        )
    }
}