import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import {ProjetDiebackTree} from "../../../interfaces/project_interface";
import {
    calculateArchiStatistics,
    calculateDeperisStatistics,
    DataItem,
    LIST_COLOR_ARCHI,
    LIST_COLOR_DEPERIS
} from "./common/DeperissementHelpers";

interface VerticalBarChartProps {
    data: ProjetDiebackTree[];
}


const styles = StyleSheet.create({
    pageContainer: {
        width: '100%',
    },
    container: {
        width: '100%',
        padding: 10,
    },
    title: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    chartContainer: {
        height: 250,
        flexDirection: 'row',
        position: 'relative',
        marginBottom: 20,
    },
    mainChartArea: {
        flex: 1,
        height: '100%',
        position: 'relative',
        backgroundColor: 'white',
    },
    gridLinesContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    gridLine: {
        position: 'absolute',
        left: 0,
        right: 0,
        borderBottom: '1 dotted #CCCCCC',
        height: 0,
    },
    scaleContainer: {
        width: 40,
        height: '100%',
        position: 'relative',
        marginLeft: 5,
    },
    yAxisLabel: {
        fontSize: 8,
        position: 'absolute',
        left: 5,
    },
    barsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-end',
        height: '100%',
        position: 'relative',
        paddingHorizontal: 10,
    },
    barWrapper: {
        alignItems: 'center',
        width: 30,
    },
    bar: {
        width: 20,
        position: 'relative',
    },
    barLabel: {
        fontSize: 9,
        fontWeight: 'bold',
        marginTop: 3,
        width: 50,
        textAlign: 'center',
    },
    barValue: {
        fontSize: 7,
        marginBottom: 1,
    },
    legendContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 10,
        padding: 5,
    },
    legendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
    },
    legendSquare: {
        width: 10,
        height: 10,
        marginRight: 5,
    },
    legendText: {
        fontSize: 8,
    },
    legendWrapper: {
        marginTop: 4,
        paddingTop: 10,
    },

});

const BarChart: React.FC<{ title: string, data: DataItem[] }> = ({ title, data }) => {
    const gridLines: number[] = Array.from({ length: 6 }, (_, i) => i * 20);

    return (
        <View style={styles.container} wrap={false}>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.chartContainer}>
                <View style={styles.mainChartArea}>
                    <View style={styles.gridLinesContainer}>
                        {gridLines.map((percentage: number) => (
                            <View
                                key={percentage}
                                style={[styles.gridLine, { bottom: `${percentage}%` }]}
                            />
                        ))}
                    </View>

                    <View style={styles.barsContainer}>
                        {data.map((item: DataItem) => (
                            <View key={item.percentage} style={styles.barWrapper}>
                                <Text style={styles.barValue}>
                                    {item.percentage}%
                                </Text>
                                <View
                                    style={[styles.bar, {
                                        height: `${item.percentage}%`,
                                        backgroundColor: item.color,
                                    }]}
                                />
                            </View>
                        ))}
                    </View>
                </View>

                <View style={styles.scaleContainer}>
                    {gridLines.map((percentage: number) => (
                        <Text
                            key={percentage}
                            style={[styles.yAxisLabel, {
                                bottom: `${percentage}%`,
                                transform: `translateY(${-4}px)`
                            }]}
                        >
                            {percentage}%
                        </Text>
                    ))}
                </View>
            </View>
                <View style={styles.legendWrapper}>
                    <View style={styles.legendContainer}>
                        {/* ARCHI */}
                        {title === "Protocole ARCHI" &&
                            Object.entries(LIST_COLOR_ARCHI).map(([state, config]) => (
                                <View key={state} style={styles.legendItem}>
                                    <View style={[styles.legendSquare, { backgroundColor: config.color }]} />
                                    <Text style={styles.legendText}>{state}</Text>
                                </View>
                            ))
                        }
                        {/* DEPERIS */}
                        {title === "Protocole DEPERIS" &&
                            Object.entries(LIST_COLOR_DEPERIS).map(([note, config]) => (
                                <View key={note} style={styles.legendItem}>
                                    <View style={[styles.legendSquare, { backgroundColor: config.color }]} />
                                    <Text style={styles.legendText}>{note}</Text>
                                </View>
                            ))
                        }
                    </View>
                </View>
        </View>
    );
};

const DeperissementDiagramPDF: React.FC<VerticalBarChartProps> = ({ data }) => {
    const archiStats = calculateArchiStatistics(data);
    const deperisStats = calculateDeperisStatistics(data);

    return (
        <View style={styles.pageContainer}>
            <BarChart title="Protocole ARCHI" data={archiStats} />
            <BarChart title="Protocole DEPERIS" data={deperisStats} />
        </View>
    );
};

export default DeperissementDiagramPDF;