export const VigilenceColors = {
    colors: [
        '#4E7925',
        '#72951D',
        '#96B017',
        '#BCCD11',
        '#FEF430',
        '#FFC058',
        '#FF8805',
        '#FF240D',
        '#E20000',
        '#AC0000',
        '#404040',
    ],

    getVigilenceColor: (index: number): string => {
        if (index >= 0 && index <= 10) {
            return VigilenceColors.colors[index];
        }
        throw new Error(`Index de vigilence inconnu (<0 ou >10) : index = ${index}`);
    }
};

export default VigilenceColors;