import React from "react";
import {Text, View, Image} from '@react-pdf/renderer';
import {DiagnosticEssenceNonIBS, TemperatureNonIBS} from "../../../interfaces/Diagnostic_interface";
import {getBorderColor} from "../../../assets/styles/inbColors";
import {formatEssence, getSumTemperature} from "./common/INBHelpers";
import {stylesTable} from "./common/StylesTable";
import BasePDFTable from "./common/BasePDFTable";

interface INBIconProps {
    temperature: TemperatureNonIBS;
}

type IconType = 'sun' | 'pickaxe' | 'mountain';
type ColorType = 'green' | 'yellow' | 'red';


const INBIconPdf: React.FC<INBIconProps> = ({temperature}) => {
    const getIconPath = (icon: IconType, color: string): string => {
        let colorName: ColorType;
        if (color === '#008000') {
            colorName = 'green';
        } else if (color === '#FFA500') {
            colorName = 'yellow';
        } else {
            colorName = 'red';
        }
        return require(`../../../assets/images/iconsPdf/ic_${icon}_${colorName}.png`);
    };

    const borderColor = getBorderColor(
        temperature.climatique.color,
        temperature.pedologique.color,
        temperature.topographique.color
    );

    return (
        <View style={[stylesTable.iconContainer, {borderColor}]}>
            <Image
                src={getIconPath('sun', temperature.climatique.color)}
                style={stylesTable.icon}
            />
            <Image
                src={getIconPath('pickaxe', temperature.pedologique.color)}
                style={stylesTable.icon}
            />
            <Image
                src={getIconPath('mountain', temperature.topographique.color)}
                style={stylesTable.icon}
            />
        </View>
    );
};

const INBTablePDF: React.FC<{ diagnosticsEssenceNonIBS: DiagnosticEssenceNonIBS[] }> = ({diagnosticsEssenceNonIBS}) => {
    const sortedData = diagnosticsEssenceNonIBS.slice().sort((a, b) => {
        return getSumTemperature(a.temperature) - getSumTemperature(b.temperature);
    });

    const renderCell = (diagnostic: DiagnosticEssenceNonIBS, columnIndex: number) => {
        if (columnIndex === 0) {
            return <Text>{formatEssence(diagnostic.essence)}</Text>;
        }
        return <INBIconPdf temperature={diagnostic.temperature[columnIndex - 1]}/>;
    };

    return <BasePDFTable data={sortedData} renderCell={renderCell} />;
};
export default INBTablePDF;