import { Button, CircularProgress, TextareaAutosize } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { CguApi } from '../../api/cgus_api'
import { CGU } from '../../interfaces/cgu_interface'
import AppHeader from "../../components/common/AppHeader";

export interface CGUProps {}

export interface CGUState {
    loaded: boolean // TODO : supprimer cette propriété
    editing: boolean
    cgu: CGU // TODO : remplacer par une string, initialement null
}

export class CGUs extends React.Component<CGUProps, CGUState> {
    constructor(props: CGUProps) {
        super(props)

        this.state = {
            loaded: false,
            editing: false,
            cgu: {
                content: '',
            },
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            // TODO : remplacer par un await
            CguApi.getLatestCGU().then((response) => {
                this.setState({ cgu: response })
            })
            this.setState({ loaded: true })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement des CGU')
        }
    }

    render() {
        return (
            <Box>
                {this.state.loaded ? (
                    <Box sx={{ p: 5, height: 'calc(100vh - 100px)' }}>
                        <AppHeader title="Conditions générales d'utilisation" />
                        {this.state.editing || !this.state.cgu ? (
                            <Box
                                sx={{
                                    height: 0.75,
                                    overflow: 'auto',
                                    border: 1,
                                    p: 5,
                                }}
                            >
                                <TextareaAutosize
                                    style={{
                                        width: '100%',
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                    value={this.state.cgu.content}
                                    onChange={(event) => {
                                        const cgu = this.state.cgu
                                        cgu.content = event.target.value
                                        this.setState({ cgu: cgu })
                                    }}
                                />
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    border: 1,
                                    height: 0.75,
                                    overflow: 'auto',
                                    p: 5,
                                }}
                                onClick={() => {
                                    this.setState({ editing: true })
                                }}
                            >
                                {this.state.cgu.content
                                    .split('\n')
                                    .map((string, index) => (
                                        <p key={index}>
                                            {string}
                                            <br />
                                        </p>
                                    ))}
                            </Box>
                        )}

                        <Box sx={{ p: 5, px: 80 }}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={async (event) => {
                                    // TODO : créer une méthode classe pour effectuer ce traitement
                                    await CguApi.createCGUs(
                                        this.state.cgu
                                    ).then(() => {
                                        this.setState({
                                            editing: false,
                                        })
                                        this.load()
                                    })
                                }}
                            >
                                Enregistrer
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: 1,
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        )
    }
}
