import {
    mdiDelete,
    mdiDownload,
    mdiRadioboxBlank,
    mdiRadioboxMarked,
} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import * as React from 'react'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import {AlgosJavascriptApi} from '../../api/algos_javascript_api'
import {GeneralFunctionsApi} from '../../api/general_functions_api'
import {AlgosJavascript} from '../../interfaces/algosJavascript_interface'

interface AlgosJSTableProps {
    algos_js: AlgosJavascript[]
    load: () => void
}

interface AlgosJSTableState {
    current_algo: AlgosJavascript
    delete: boolean
}

export class AlgosJSTable extends React.Component<
    AlgosJSTableProps,
    AlgosJSTableState
> {
    constructor(props: AlgosJSTableProps) {
        super(props)
        this.state = {
            current_algo: {
                inUse: false,
                betaUse: false,
                message: '',
                content: '',
            },
            delete: false,
        }
    }

    render() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead
                            sx={{
                                backdropFilter: 2,
                                backgroundColor: 'primary.main',
                            }}
                        >
                            <TableRow>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Message
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Date d'enregistrement
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Active
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    En bêta
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {GeneralFunctionsApi.SortByIDReverse(
                                this.props.algos_js
                            ).map((algo, index) => (
                                <TableRow key={index}
                                          sx={{
                                              '&:hover': {
                                                  backgroundColor: 'background.default',
                                              },
                                          }}
                                >
                                    <TableCell sx={{py: 0}}>
                                        {algo.message}
                                    </TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {new Date(
                                            algo.timeInsert * 1000 // passage en millisecondes
                                        ).toLocaleDateString()}
                                    </TableCell>
                                    <ToggleCell
                                        bool={algo.inUse}
                                        action={async () => {
                                            // TODO : créer une méthode de classe pour efffectuer ce traitemnet
                                            try {
                                                await AlgosJavascriptApi.toggleActiveAlgosJS(
                                                    algo
                                                ).then((res) => {
                                                    this.props.load()
                                                })
                                            } catch (e) {
                                                console.error(e)
                                                alert(
                                                    'Echec de la modification'
                                                )
                                            }
                                        }}
                                    />
                                    <ToggleCell
                                        bool={algo.betaUse}
                                        action={async () => {
                                            // TODO : créer une méthode classe (envisager de réutiliser la même que juste au-dessus)
                                            try {
                                                await AlgosJavascriptApi.toggleBetaUseAlgosJS(
                                                    algo
                                                ).then((res) => {
                                                    this.props.load()
                                                })
                                            } catch (e) {
                                                console.error(e)
                                                alert(
                                                    'Echec de la modification'
                                                )
                                            }
                                        }}
                                    />
                                    <TableCell sx={{py: 0}}>
                                        <List>
                                            <ListItem sx={{p: 0, my: 1}}>
                                                <Tooltip
                                                    title="Télécharger"
                                                    placement="right"
                                                >
                                                    <Button
                                                        sx={{py: 0}}
                                                        color="info"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            // TODO : Créer une méthode de classe pour gérer ce traitement. Ce code n'a rien à faire ici
                                                            const blob =
                                                                new Blob(
                                                                    [
                                                                        algo.content,
                                                                    ],
                                                                    {
                                                                        type: 'application/x-javascript',
                                                                    }
                                                                )
                                                            const fileUrl =
                                                                URL.createObjectURL(
                                                                    blob
                                                                )
                                                            const a =
                                                                document.createElement(
                                                                    'a'
                                                                )
                                                            a.download = `bioclimsol_algorithme_${algo.timeInsert}.js`
                                                            a.href = fileUrl
                                                            a.click()
                                                            a.remove()
                                                            URL.revokeObjectURL(
                                                                fileUrl
                                                            )
                                                        }}
                                                    >
                                                        <Icon
                                                            path={mdiDownload}
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            </ListItem>
                                            {algo.inUse || algo.betaUse ? (
                                                <></>
                                            ) : (
                                                <ListItem sx={{p: 0, my: 1}}>
                                                    <Tooltip
                                                        title="Supprimer"
                                                        placement="right"
                                                    >
                                                        <Button
                                                            sx={{py: 0}}
                                                            color="error"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                this.setState({
                                                                    current_algo:
                                                                    algo,
                                                                    delete: true,
                                                                })
                                                            }}
                                                        >
                                                            <Icon
                                                                path={mdiDelete}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </ListItem>
                                            )}
                                        </List>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.delete ? (
                    <Areyousure
                        message={
                            "supprimer une donnée d'algorithme de la base de données"
                        }
                        close={() => {
                            this.setState({delete: false})
                        }}
                        action={async () => {
                            // TODO : assurer la gestion des erreurs avec un try / catch
                            await AlgosJavascriptApi.deleteAlgosJS(
                                this.state.current_algo
                            ).then((response) => {
                                this.props.load()
                            })
                        }}
                        reload={this.props.load}
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}

function ToggleCell(props: { bool: boolean; action: () => void }) {
    return (
        <TableCell sx={{py: 0}}>
            {props.bool ? (
                <Button color="secondary" onClick={props.action}>
                    <Icon path={mdiRadioboxMarked}/>
                </Button>
            ) : (
                <Tooltip title="Utiliser">
                    <Button color="inherit" onClick={props.action}>
                        <Icon path={mdiRadioboxBlank}/>
                    </Button>
                </Tooltip>
            )}
        </TableCell>
    )
}
