import {Text} from '@react-pdf/renderer';
import React from "react";
import {sortByVigilence, VigilenceCircle} from "./common/vigilenceHelpers";
import {DiagnosticEssenceIBS} from "../../../interfaces/Diagnostic_interface";
import {formatEssence} from "./common/INBHelpers";
import BasePDFTable from "./common/BasePDFTable";



const IBSTablePDF: React.FC<{ diagnosticsEssenceIBS: DiagnosticEssenceIBS[] }> = ({ diagnosticsEssenceIBS }) => {
    const sortedData = sortByVigilence(diagnosticsEssenceIBS);

    const renderCell = (diagnostic: DiagnosticEssenceIBS, columnIndex: number) => {
        if (columnIndex === 0) {
            return <Text>{formatEssence(diagnostic.essence)}</Text>;
        }
        return <VigilenceCircle vigilence={diagnostic.temperature[columnIndex - 1].vigilence} isPDF={true}/>;
    };

    return <BasePDFTable data={sortedData} renderCell={renderCell} />;
};
export default IBSTablePDF;