import { mdiDownload, mdiUpload } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import { FunderApi } from '../../api/funders_api'
import { FunderCategory } from '../../interfaces/funder_interface'
import FundersTable from './funders_table'
import AppHeader from "../../components/common/AppHeader";

export interface FundersProps {}

interface FundersState {
    funders?: FunderCategory[]
    cancel: boolean
}

export class Funders extends React.Component<FundersProps, FundersState> {
    constructor(props: FundersProps) {
        super(props)
        this.state = {
            cancel: false,
        }
        this.editFunders = this.editFunders.bind(this)
        this.load = this.load.bind(this)
        this.downloadFunders = this.downloadFunders.bind(this)
    }

    componentDidMount(): void {
        this.load()
        window.onbeforeunload = function () {
            return "Tout ce qui n'a pas été enregistré sera perdu. Êtes vous sûr de vouloir reloader cette page ?"
        }
    }

    async load() {
        try {
            const funders = await FunderApi.listFunderCategory()
            this.setState({ funders: funders })
        } catch (e) {
            console.error(e)
            alert('Echec de la récupérations des données financeurs')
        }
    }

    editFunders(funders: FunderCategory[]) {
        this.setState({ funders: funders })
    }

    downloadFunders() {
        const blob = new Blob([JSON.stringify(this.state.funders!)], {
            type: 'application/json',
        })
        const fileUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = `Bioclimsol_Funders.json`
        a.href = fileUrl
        a.click()
        a.remove()
        URL.revokeObjectURL(fileUrl)
    }

    render() {
        return (
            <Box sx={{ padding: 5 }}>
                <AppHeader title="Financeurs" />
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Button
                        sx={{ mb: 2 }}
                        variant="outlined"
                        onClick={() => {
                            // TODO : déplacer ce code dans une méthode de classe
                            const funders = this.state.funders
                            funders!.push({
                                title: 'Nouvelle category',
                                listFundingEntry: [],
                            })
                            this.editFunders(funders!)
                        }}
                    >
                        Ajouter une catégorie
                    </Button>
                    {this.state.funders ? (
                        <Tooltip title="Télécharger">
                            <Button
                                sx={{ mb: 2, mx: 4 }}
                                variant="text"
                                onClick={this.downloadFunders}
                            >
                                <Icon path={mdiDownload} />
                            </Button>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    <Tooltip title="Téléverser">
                        <Button variant="text" component="label">
                            <Icon path={mdiUpload} className="app_svg" />
                            <input
                                type="file"
                                accept=".json"
                                hidden
                                onChange={async (event) => {
                                    // TODO : déplacer ce code dans une méthode de classe
                                    if (event.target.files) {
                                        try {
                                            this.downloadFunders()
                                            const funders = JSON.parse(
                                                await event.target.files[0].text()
                                            )
                                            FunderApi.replaceFunders(
                                                funders
                                            ).then(() => {
                                                this.load()
                                            })
                                        } catch (e) {
                                            console.error(e)
                                            alert(
                                                'echec du remplacement des crédits'
                                            )
                                        }
                                    }
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Box>
                {this.state.funders ? (
                    <FundersTable
                        funders={this.state.funders}
                        editFunders={this.editFunders}
                    />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 1,
                            height: 700,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <Box sx={{ px: 40, display: 'flex' }}>
                    <Button
                        sx={{ mt: 5, mx: 1 }}
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            // TODO :  déplacer ce code dans une méthode de classe
                            try {
                                FunderApi.replaceFunders(this.state.funders!)
                                alert('Les financeurs ont été sauvegardés')
                                this.load()
                            } catch (e) {
                                console.error(e)
                                alert('Echec de la sauvegarde')
                            }
                        }}
                    >
                        Enregistrer
                    </Button>
                    <Button
                        sx={{ mt: 5, mx: 1 }}
                        fullWidth
                        variant="outlined"
                        color="inherit"
                        onClick={() => {
                            this.setState({ cancel: true })
                        }}
                    >
                        Annuler les modifications
                    </Button>
                </Box>
                {this.state.cancel ? (
                    <Areyousure
                        message="annuler les modifications apportés"
                        reload={() => {}}
                        action={this.load}
                        close={() => {
                            this.setState({ cancel: false })
                        }}
                    />
                ) : (
                    <></>
                )}
            </Box>
        )
    }
}
