import {
    mdiArrowDownBold,
    mdiArrowUpBold,
    mdiDelete,
    mdiPlus,
    mdiUpload,
} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Box,
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import * as React from 'react'
import { EditCell } from '../../components/EditCell/EditCell'
import { GeneralFunctionsApi } from '../../api/general_functions_api'
import { FunderCategory, FunderEntry } from '../../interfaces/funder_interface'

interface FundersTableProps {
    funders: FunderCategory[]
    editFunders: (funders: FunderCategory[]) => void
}

interface FundersTableState {
    // TODO : ce champ est inutilisé, le supprimer, ainsi que ses références
    delete: boolean
}

class FundersTable extends React.Component<
    FundersTableProps,
    FundersTableState
> {
    constructor(props: FundersTableProps) {
        super(props)
        this.state = {
            delete: false,
        }
    }

    async handleNewLogo(
        event: any,
        category_index: number,
        entry_index: number
    ) {
        const base64_img = await GeneralFunctionsApi.convertToBase64(
            event.target.files[0]
        )
        const funders = this.props.funders
        funders[category_index].listFundingEntry[entry_index].logo = base64_img
        this.props.editFunders(funders)
    }

    changeEntryLink(text: string, category_index: number, entry_index: number) {
        const funders = this.props.funders
        funders[category_index].listFundingEntry[entry_index].link = text
        this.props.editFunders(funders)
    }

    deleteEntry(category_index: number, entry_index: number) {
        const funders = this.props.funders
        funders[category_index].listFundingEntry.splice(entry_index, 1)
        this.props.editFunders(funders)
    }

    changeCategoryTitle(index: number, text: string) {
        const funders = this.props.funders
        funders[index].title = text
        this.props.editFunders(funders)
    }

    render() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead
                            sx={{
                                backdropFilter: 2,
                                backgroundColor: 'primary.main',
                            }}
                        >
                            <TableRow>
                                <TableCell sx={{ color: '#ffffff' }}>
                                    Titre
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell sx={{ color: '#ffffff' }}>
                                    Logo
                                </TableCell>
                                <TableCell sx={{ color: '#ffffff' }}>
                                    Lien
                                </TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.funders.map(
                                (
                                    funder: FunderCategory,
                                    category_index: number
                                ) => (
                                    <TableRow
                                        key={category_index}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'background.default',
                                            },
                                            p: 0,
                                        }}
                                    >
                                        <TableCell>
                                            <EditCell
                                                text={funder.title}
                                                index={category_index}
                                                changeValue={(
                                                    index: number,
                                                    text: string
                                                ) => {
                                                    this.changeCategoryTitle(
                                                        index,
                                                        text
                                                    )
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <List>
                                                {category_index > 0 ? (
                                                    <ListItem
                                                        sx={{ p: 0, my: 1 }}
                                                    >
                                                        <Tooltip
                                                            title="Monter"
                                                            placement="left"
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                color="info"
                                                                sx={{ p: 0 }}
                                                                onClick={() => {
                                                                    // TODO : déplacer une méthode de classe
                                                                    const funders =
                                                                        GeneralFunctionsApi.SwitchEntries(
                                                                            category_index,
                                                                            category_index -
                                                                                1,
                                                                            this
                                                                                .props
                                                                                .funders
                                                                        )
                                                                    this.props.editFunders(
                                                                        funders
                                                                    )
                                                                }}
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiArrowUpBold
                                                                    }
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ListItem>
                                                ) : (
                                                    <></>
                                                )}
                                                <ListItem sx={{ p: 0, my: 1 }}>
                                                    <Tooltip
                                                        title="Ajouter"
                                                        placement="left"
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ p: 0 }}
                                                            onClick={() => {
                                                                // TODO : déplacer une méthode de classe
                                                                const funders =
                                                                    this.props
                                                                        .funders
                                                                funders[
                                                                    category_index
                                                                ].listFundingEntry.push(
                                                                    {
                                                                        logo: 'logo',
                                                                        link: 'lien',
                                                                    }
                                                                )
                                                                this.props.editFunders(
                                                                    funders
                                                                )
                                                            }}
                                                        >
                                                            <Icon
                                                                path={mdiPlus}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </ListItem>
                                                <ListItem sx={{ p: 0, my: 1 }}>
                                                    <Tooltip
                                                        title="Détruire"
                                                        placement="left"
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ p: 0 }}
                                                            color="error"
                                                            onClick={() => {
                                                                // TODO : déplacer une méthode de classe
                                                                const funders =
                                                                    this.props
                                                                        .funders
                                                                funders.splice(
                                                                    category_index,
                                                                    1
                                                                )
                                                                this.props.editFunders(
                                                                    funders
                                                                )
                                                            }}
                                                        >
                                                            <Icon
                                                                path={mdiDelete}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </ListItem>
                                                {category_index <
                                                this.props.funders.length -
                                                    1 ? (
                                                    <ListItem
                                                        sx={{ p: 0, my: 1 }}
                                                    >
                                                        <Tooltip
                                                            title="Descendre"
                                                            placement="left"
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                color="info"
                                                                sx={{ p: 0 }}
                                                                onClick={() => {
                                                                    // TODO : déplacer une méthode de classe
                                                                    const funders =
                                                                        GeneralFunctionsApi.SwitchEntries(
                                                                            category_index,
                                                                            category_index +
                                                                                1,
                                                                            this
                                                                                .props
                                                                                .funders
                                                                        )
                                                                    this.props.editFunders(
                                                                        funders
                                                                    )
                                                                }}
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiArrowDownBold
                                                                    }
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ListItem>
                                                ) : (
                                                    <></>
                                                )}
                                            </List>
                                        </TableCell>

                                        <TableCell sx={{ p: 0 }}>
                                            <List sx={{ p: 0 }}>
                                                {funder.listFundingEntry.map(
                                                    (
                                                        entry: FunderEntry,
                                                        entry_index: number
                                                    ) => (
                                                        <ListItem
                                                            sx={{
                                                                '& td': {
                                                                    border: 0,
                                                                },
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#0084730c',
                                                                },
                                                                p: 0,
                                                            }}
                                                            key={entry_index}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    width: 1,
                                                                    p: 2,
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        entry.logo
                                                                    }
                                                                    alt="logo"
                                                                    style={{
                                                                        maxHeight: 50,
                                                                        maxWidth: 50,
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Tooltip
                                                                        title="Uploader"
                                                                        placement="right"
                                                                    >
                                                                        <Button
                                                                            variant="text"
                                                                            color="inherit"
                                                                            component="label"
                                                                            sx={{
                                                                                mx: 2,
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                className="app_svg"
                                                                                path={
                                                                                    mdiUpload
                                                                                }
                                                                            />
                                                                            <input
                                                                                type="file"
                                                                                accept=".jpeg, .png, .jpg"
                                                                                hidden
                                                                                id={entry.id?.toString()}
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    this.handleNewLogo(
                                                                                        event,
                                                                                        category_index,
                                                                                        entry_index
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Box>
                                                            </Box>
                                                        </ListItem>
                                                    )
                                                )}
                                            </List>
                                        </TableCell>

                                        <TableCell sx={{ p: 0 }}>
                                            <List sx={{ p: 0 }}>
                                                {funder.listFundingEntry.map(
                                                    (
                                                        entry: FunderEntry,
                                                        entry_index: number
                                                    ) => (
                                                        <ListItem
                                                            sx={{
                                                                '& td': {
                                                                    border: 0,
                                                                },
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#0084730c',
                                                                },
                                                                p: 0,
                                                            }}
                                                            key={entry_index}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    width: 1,
                                                                    p: 2,
                                                                }}
                                                            >
                                                                <EditCell
                                                                    text={
                                                                        entry.link
                                                                    }
                                                                    index={
                                                                        entry_index
                                                                    }
                                                                    changeValue={(
                                                                        index: number,
                                                                        text: string
                                                                    ) => {
                                                                        this.changeEntryLink(
                                                                            text,
                                                                            category_index,
                                                                            index
                                                                        )
                                                                    }}
                                                                />
                                                            </Box>
                                                        </ListItem>
                                                    )
                                                )}
                                            </List>
                                        </TableCell>

                                        <TableCell>
                                            <List>
                                                {funder.listFundingEntry.map(
                                                    (
                                                        entry: FunderEntry,
                                                        entry_index: number
                                                    ) => (
                                                        <ListItem
                                                            sx={{
                                                                '& td': {
                                                                    border: 0,
                                                                },
                                                                p: 0,
                                                                my: 1,
                                                            }}
                                                            key={entry_index}
                                                        >
                                                            <Tooltip
                                                                title="Détruire"
                                                                placement="right"
                                                            >
                                                                <Button
                                                                    color="error"
                                                                    variant="outlined"
                                                                    sx={{
                                                                        p: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        this.deleteEntry(
                                                                            category_index,
                                                                            entry_index
                                                                        )
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        path={
                                                                            mdiDelete
                                                                        }
                                                                    />
                                                                </Button>
                                                            </Tooltip>
                                                        </ListItem>
                                                    )
                                                )}
                                            </List>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default FundersTable
