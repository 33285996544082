import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { get_unrestricted_url } from '../../global_variables/backend_url_api'
import { AuthToken } from '../../api/token_api'
import { UserApi } from '../../api/users_api'

export interface ComponentProps {
    user: any
}

interface Componentstate {
    password: string
    password_confirmation: string
    stringRegex: string[]
    minLength: number
    minComplexity: number
}

// TODO : renommer en ResetPasswordRoute
class NeedPasswordChange extends React.Component<
    ComponentProps,
    Componentstate
> {
    constructor(props: any) {
        super(props)
        this.state = {
            password: '',
            password_confirmation: '',
            stringRegex: [],
            minLength : 0,
            minComplexity : 0,
        }

        this.performChange = this.performChange.bind(this)
    }

    async componentDidMount(): Promise<void> {
        await this.load()
    }

    async load() {
        try {
            const data = await this.getRegex()
            this.setState({
                //stringRegex: data.regex,
                stringRegex : ['[a-z]', '[A-Z]', '[0-9]', '[^a-zA-Z0-9]'],
                minLength : data.minLength,
                minComplexity : data.minComplexity
                })
        } catch (e) {
            console.error(e)
            alert("Impossible d'obtenir le schéma du mot de passe")
        }
    }


    // TODO : créer et utiliser un fichier dans le dossier `functions` (ou `api` s'il a été renomé)
    async getRegex() {
        const response = await fetch(
            `${get_unrestricted_url()}/config/password_policy`,
            {
                headers: { Authorization: AuthToken.get() },
            }
        )
        return response.json()
    }

    // Transformer cette fonction en getter, et lui donner un nom plus explicite
    tryPasswords() {
        return this.state.password === this.state.password_confirmation
    }

    checkPassword() {

        let count = 0;
        for(let i = 0; i < this.state.stringRegex.length; i++){
            const finalRegex = new RegExp(this.state.stringRegex[i]);
            if (this.state.password.match(finalRegex) != null){
                count++
                }
        }
        return count >= this.state.minComplexity && this.state.password.length >= this.state.minLength
    }

    disconnect() {
            localStorage.removeItem('userToken')
            window.location.pathname = '/'
        }

    isPasswordNull() {
        return this.state.password.length > 0
    }

    async performChange() {
        try {
            await UserApi.changePassword(this.state.password)
            alert('Mot de passe enregistré !')
        } catch (e) {
            console.error(e)
            alert('Un problème a empêché la modification du mot de passe.')
        }
        localStorage.removeItem('userToken')
        localStorage.setItem('updated', 'true')
        window.location.href = '/'
    }

    render() {
        return (
            <Box
                    sx={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                className="change-password-bg"
                  >
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button variant="contained" color="secondary" onClick={this.disconnect}>
                        Déconnexion
                      </Button>
                    </Box>
            <Box
                sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}

            >

                <FormControl
                    sx={{
                        width: '30vw',
                        textAlign: 'center',
                        border: 1,
                        p: 5,
                        borderRadius: 1,
                        backdropFilter: 'blur(7px)',
                        WebkitBackdropFilter: 'blur(7px)',
                        backgroundColor: '#ffffff1f',
                        borderColor: '#ffffffa1',
                    }}
                >
                    <Typography sx={{ mb: 5 }}>
                        <h1>Changez votre mot de passe</h1>
                    </Typography>
                    <TextField
                        onChange={(event) => {
                            this.setState({
                                password: event.target.value,
                            })
                        }}
                        type="password"
                        name="password"
                        id="password"
                        label="Mot de passe"
                        sx={{
                            my: 2,
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: this.state.password ? (this.checkPassword() ? 'green' : 'red') : '',
                                },
                                '&:hover fieldset': {
                                  borderColor: this.state.password  ? (this.checkPassword() ? 'green' : 'red') : '',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: this.state.password  ? (this.checkPassword() ? 'green' : 'red') : '',
                                }
                              }
                            }}

                    />
                    <TextField
                      onChange={(event) => {
                        this.setState({
                          password_confirmation: event.target.value,
                        });
                      }}
                      sx={{
                          my: 2,
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: this.state.password_confirmation ? ((this.tryPasswords() && this.checkPassword()) ? 'green' : 'red') : '',
                              },
                              '&:hover fieldset': {
                                borderColor: this.state.password_confirmation  ? ((this.tryPasswords() && this.checkPassword()) ? 'green' : 'red') : '',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: this.state.password_confirmation  ? ((this.tryPasswords() && this.checkPassword()) ? 'green' : 'red') : '',
                              }
                            }
                          }}
                      type="password"
                      name="password_confirmation"
                      id="password_confirmation"
                      label="Confirmer le mot de passe"
                    />
                       {this.checkPassword() && this.tryPasswords() && this.isPasswordNull() ? (
                                                                   <Button
                                                                       onClick={this.performChange}
                                                                       variant="contained"
                                                                   >
                                                                       valider
                                                                   </Button>
                                                               ) : (
                                                                   <></>
                                                               )}

                    <Box sx={{ textAlign: 'left' }}>
                        <p>
                            {this.tryPasswords() ? (
                                ''
                            ) : (
                                <>
                                    Les mots de passe doivent être{' '}
                                    <b>identiques</b>
                                </>
                            )}
                        </p>
                        <p>
                            {
                                this.checkPassword() ? (
                                ''
                            ) : (
                                <>
                                    Le mot de passe doit faire <b>{this.state.minLength}</b> ou plus
                                    caractères de long, contenir (au moins) un{' '}
                                    <b>caractère spécial</b>, une lettre{' '}
                                    <b>majuscule</b>, une lettre{' '}
                                    <b>minuscule</b> et un <b>chiffre</b>.
                                </>
                            )}
                        </p>
                    </Box>
                </FormControl>
            </Box>
            </Box>
        )
    }
}

export default NeedPasswordChange
