export const PropDeperisTreeEnum = {
    ZERO: '0%',
    LOWER_20: '< 20%',
    GREATER_OR_EQUAL_20: '>= 20%',
}

export const PropDeperisTreeMapping:{ [key: string]: string }  = {
    '0': PropDeperisTreeEnum.ZERO,
    '1': PropDeperisTreeEnum.LOWER_20,
    '2': PropDeperisTreeEnum.GREATER_OR_EQUAL_20,
};
