import logo from '../../assets/images/logo_bioclimsol_solo.png'
import {AuthToken} from '../../api/token_api'

import {Alert, Paper, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React from 'react'
import {UserApi} from '../../api/users_api'
import {ApiError} from '../../api/api_client'

interface LoginState {
    email: string
    password: string
    showPassword: boolean;
    error_message: string
}

export class Login extends React.Component<{}, LoginState> {
    constructor(props: {}) {
        super(props)
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            error_message: '',
        }
    }


    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value});
    };

    // TODO : intégrer cette fonction à loguser
    saveUser(authentification_response: any) {
        AuthToken.set(authentification_response.token)

        window.location.href = '/' // HINT : Fonctionne, explorer le mécanisme pour comprendre pourquoi
    }

    async logUser() {
        try {
            const authentification_response = await UserApi.loginWithPassword(
                this.state.email,
                this.state.password
            )
            this.saveUser(authentification_response)
        } catch (e) {
            // TODO : Exemple gestion des erreurs
            if (e instanceof ApiError) {
                // TODO : refactoriser avec un switch case
                if (e.code === 401) {
                    this.setState({
                        error_message:
                            "Accès refusé : vous n'êtes pas autorisé à accéder à cette ressource.",
                    })
                }
                if (e.code === 403 || e.code === 404) {
                    this.setState({
                        error_message:
                            "L'identifiant ou le mot de passe est incorrect",
                    })
                }
                if (e.code === 406) {
                    this.setState({
                        error_message:
                            "Désolé, vous ne pouvez pas accéder à votre compte car il a été désactivé. Veuillez contacter notre administrateur pour obtenir de l'aide.",
                    })
                }
                if (e.code === 409) {
                    this.setState({
                        error_message:
                            "Accès restreint : Ce formulaire est réservé aux utilisateurs standard. Veuillez vous authentifier en utilisant le bouton 'Accès Employé CNPF'.",
                    })
                }
                if (e.code === 412) {
                    this.setState({
                        error_message:
                            'La licence associée à ce compte est expirée. Veuillez nous contacter pour renouveler votre licence.',
                    })
                }
                if (e.code === 429) {
                    this.setState({
                        error_message:
                            'Trop de tentatives de connexion, veuillez réessayer ultérieurement.',
                    })
                }
            }
        }
    }

    async handleForgottenPassword() {
        const response = await UserApi.forgottenPassword()

        alert(
            `Veuillez contacter notre équipe de support à l'adresse mail suivante pour demander une réinitialisation de votre mot de passe : ${response.mail} `
        )
    }

    render() {
        const {password, showPassword} = this.state;

        return (
            <Box sx={{display: 'flex', minHeight: '100vh', width: '100%'}}>
                <Box
                    id="login_background"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        },
                        width: {md: '60%'},
                        backgroundSize: 'cover',
                        objectFit: 'contain'
                    }}
                />
                <Box
                    sx={{
                        width: {
                            xs: '100%',
                            md: '40%'
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2
                    }}
                >
                    <Box
                        component={Paper}
                        sx={{
                            width: '100%',
                            maxWidth: {
                                xs: '100%',
                                sm: '500px'
                            },
                            display: 'flex',
                            paddingTop: '2rem',
                            paddingBottom: '3rem',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                        }}
                    >
                        <img
                            src={logo}
                            alt="Logo de BioClimSol"
                            style={{
                                width: '40%',
                                height: 'auto',
                                padding: 1,
                                maxWidth: '180px',
                            }}
                            className="login_logo"
                        />
                        <Typography
                            sx={{
                                color: 'gray',
                                fontSize: '0.9rem',
                                mt: 1,
                            }}>
                            Bienvenue connectez-vous à votre compte
                        </Typography>

                        <Box
                            component="form"
                            onSubmit={(event) => {
                                event.preventDefault()
                                this.logUser()
                            }}
                            noValidate
                            sx={{
                                mt: 3,
                                width: {xs: '90%', sm: '70%'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {this.state.error_message && (
                                <Alert
                                    severity="error"
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: 'secondary.main',
                                        '& .MuiAlert-icon': {
                                            color: 'secondary.main',
                                        }
                                    }}
                                >
                                    {this.state.error_message}
                                </Alert>
                            )}

                            <TextField
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                type="text"
                                autoComplete="email"
                                autoFocus
                                onChange={(event) => {
                                    this.setState({email: event.target.value})
                                }}
                                sx={{mb: 2}}
                            />

                            <TextField
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                value={password}
                                onChange={this.handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 10,
                                    width: {xs: '80%', sm: '70%'},
                                    textTransform: 'none'
                                }}
                            >
                                Se connecter
                            </Button>


                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                sx={{
                                    mt: 3,
                                    width: {xs: '80%', sm: '70%'},
                                    textTransform: 'none'
                                }}
                                onClick={UserApi.startOpenIdProtocol}
                            >
                                Accès employé CNPF
                            </Button>

                            <Box sx={{mt: 3}}
                            >
                                <Grid item xs>
                                    <Link
                                        onClick={this.handleForgottenPassword}
                                        color="secondary"
                                        href="#"
                                        variant="body2"
                                    >
                                        Mot de passe oublié ?
                                    </Link>
                                </Grid>
                            </Box>

                        </Box>
                    </Box>
                </Box>

            </Box>
        )
    }
}
