import {Typography, useMediaQuery, useTheme} from '@mui/material'
import { Box } from '@mui/system'
import logo_cnpf from '../../assets/images/logo_bioclimsol.png'

const Home = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getImageWidth = () => {
        if (isMobile) return '100%';
        return '45%';
    }

    return (
        <Box
            sx={{
                height: 0.7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                m:{
                    md:15,
                    sm:9,
                    xs:2
                },
                overflow: 'hidden'
            }}
        >
            <Box>
                <img
                    style={{
                        maxWidth: getImageWidth(),
                        paddingBottom: isMobile ? 100 : 60,
                    }}
                    src={logo_cnpf}
                    alt="Cnpf Logo"
                    className="home-img"
                />
                <Typography sx={{
                    fontWeight: {
                        sm: 600,
                        xs: 500
                    },
                    fontSize: {
                        md: '3rem',
                        sm: '2rem',
                        xs: '1.5rem',
                    },
                }}>
                    Bienvenue sur l'interface d'administration
                </Typography>
                <Typography
                    sx={{
                        color: 'text.secondary', py: 2, fontSize: {
                            md: '1rem',
                            sm: '0.9em',
                            xs: '0.7rem',
                        }
                    }}
                >
                    Veuillez utiliser le menu sur la gauche pour accéder aux
                    différentes sections de l'application.
                </Typography>
            </Box>
        </Box>
    )
}

export default Home
