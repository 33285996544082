import React from 'react';
import VigilenceColors from "../../../../assets/styles/colors";
import {DiagnosticEssenceIBS} from "../../../../interfaces/Diagnostic_interface";

interface Props {
    vigilence: number;
    isPDF?: boolean;
}

export const styles = {
    circle: {
        width: 30,
        height: 30,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    vigilenceText: {
        color: 'white',
        fontSize: 12,
        fontWeight: '500'
    }
};

export const VigilenceCircle: React.FC<Props> = ({ vigilence, isPDF }) => {
    if (isPDF) {
        const { View, Text } = require('@react-pdf/renderer');
        return (
            <View style={[styles.circle, {backgroundColor: VigilenceColors.getVigilenceColor(vigilence)}]}>
                <Text style={styles.vigilenceText}>{vigilence}</Text>
            </View>
        );
    }

    return (
        <div
            style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: VigilenceColors.getVigilenceColor(vigilence),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '14px',
                fontWeight: 500
            }}
        >
            {vigilence}
        </div>
    );
};



export const sortByVigilence = (diagnosticsEssenceIBS: DiagnosticEssenceIBS[]) => {
    return diagnosticsEssenceIBS.slice().sort((a, b) => {
        const compareActuel = a.temperature[0].vigilence - b.temperature[0].vigilence;
        if (compareActuel !== 0) return compareActuel;

        const comparePlus1 = a.temperature[1].vigilence - b.temperature[1].vigilence;
        if (comparePlus1 !== 0) return comparePlus1;
        return a.temperature[2].vigilence - b.temperature[2].vigilence;
    });
};