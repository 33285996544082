export const CompactnessValuesEnum = {
    MEUBLE_PEU_COMPACT: "Meuble, peu compact",
    TRES_COMPACT: "Très compact",
    INCONNU: "Inconnue / non estimée",
};

export const CompactnessMapping:{ [key: string]: string }  = {
    'meuble_peu_compact': CompactnessValuesEnum.MEUBLE_PEU_COMPACT,
    'tres_compact': CompactnessValuesEnum.TRES_COMPACT,
    'inconnu': CompactnessValuesEnum.INCONNU,
};
