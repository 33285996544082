
export const ResilienceEnum = {
    ZERO: 'Non estimée',
    ONE: 'Nulle à très faible',
    TWO: 'Modérée',
    THREE: 'Importante',
}

export const ResilienceMapping:{ [key: string]: string }  = {
    '0': ResilienceEnum.ZERO,
    '1': ResilienceEnum.ONE,
    '2': ResilienceEnum.TWO,
    '3':ResilienceEnum.THREE
};
