import { GeoRestriction } from '../interfaces/georestriction_interface'
import { ApiClient } from './api_client'

export class GeoRestrictionApi {
    /**
     * @returns La liste des zones de restriction géographique du backend
     */
    static async listGeoRestrictions(): Promise<GeoRestriction[]> {
        return await ApiClient.get(`/georestrictions`, {restricted: false})
    }

    /**
     * Prends en paramètres l'objet georestriction et demande au back-end de créer une georestriction en base.
     * @param georestriction
     */
    static async saveGeorestriction(georestriction: GeoRestriction) {
        await ApiClient.post('/georestrictions', {
            body: JSON.stringify(georestriction),
        })
    }

    /**
     * Prends en paramètres l'objet georestriction et envoie l'information au back-end pour la modification
     * @param georestriction
     */
    static async editGeorestriction(georestriction: GeoRestriction) {
        await ApiClient.patch(`/georestrictions`, {
            body: JSON.stringify(georestriction),
        })
    }

    /**
     * prends en paramètres l'id de la georestriction et supprime la georestriction en base
     * @param id
     */
    static async deleteGeorestriction(id: number) {
        // TODO : dans la vue, gerer l'erreur `Echec de suppression de la géorestriction id : ${id}`

        await ApiClient.delete(`/georestrictions/${id}`)
    }

    /**
     * prends en paramètres l'id de la georestriction et le token. Le fetch renvoie le nombre d'Users associés à cette georestriction
     * si la réponse du back-end est supérieur à zéro, la fonction retourne false
     * @param token
     * @param id
     * @returns
     */
    // TODO : cette fonction semble inutilisée. La corriger et l'utiliser ou la supprimer
    // TODO : à appeler avant de tenter de supprimer une géorestriction
    static async isDeletable(id: number): Promise<boolean> {
        // TODO : renommer route
        // TODO : supprimer ce try / catch
        try {
            return (await ApiClient.get(
                `/web/geo_restrictions/is_destroyable/${id}`
            )) > 0
                ? true
                : false
        } catch (e) {
            throw new Error(
                `Echec de récupération du status de possibilité de suppression de la géo_restriction id : ${id}`
            )
        }
    }

    // TODO : cette fonction semble ne plus être utilisée...
    static async listGeoRestrictionsByUser(id?: number): Promise<number[]> {
        if (id === null) {
            return []
        }

        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get(`/users/georestrictions/${id}`)
        } catch (e) {
            throw new Error(
                `Echec du téléchargement de la liste des Géorestrictions de l'utilisateur id : ${id}`
            )
        }
    }

    /**
     * Récupère une géorestriction à partir de son identifiant
     */
    static async getGeoRestriction(id: number): Promise<GeoRestriction> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get(`/georestrictions/${id}`)
        } catch (e) {
            throw new Error(
                `Echec de la récupération de la géorestriction id : ${id}`
            )
        }
    }
}
