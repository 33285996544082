import {mdiContentSave, mdiDelete, mdiPencil} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextareaAutosize,
    TextField,
    Tooltip,
} from '@mui/material'
import * as React from 'react'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import {AlgosChangelogApi} from '../../api/algos_changelog_api'
import {AlgosChangelog} from '../../interfaces/algoschangelog_interface'

interface AlgosChangelogTableProps {
    logs: AlgosChangelog[]
    load: () => void
    editLogs: (logs: AlgosChangelog[]) => void
}

interface AlgosChangelogTableState {
    delete: boolean
    create: boolean
    update: boolean
    current_log: AlgosChangelog
    modified_logs: Set<number>
}

// TODO : Fusionner avec la classe route. Le découpage de ce composant n'a pas de sens
// TODO : refactoriser complètement ce fichier
export class AlgosChangelogTable extends React.Component<
    AlgosChangelogTableProps,
    AlgosChangelogTableState
> {
    constructor(props: AlgosChangelogTableProps) {
        super(props)
        this.state = {
            delete: false,
            create: false,
            update: false,
            current_log: {
                version: '',
                date: '',
                content: '',
            },
            modified_logs: new Set(),
        }
    }

    addIdToModifiedLogs(id: number) {
        if (!this.state.modified_logs.has(id)) {
            const newSet = this.state.modified_logs
            newSet.add(id)
            this.setState({modified_logs: newSet})
        }
    }

    render() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead
                            sx={{
                                backdropFilter: 2,
                                backgroundColor: 'primary.main',
                            }}
                        >
                            <TableRow>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Version
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Date
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Contenu
                                </TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.logs.map((log, index) => (
                                <TableRow key={index}
                                          sx={{
                                              '&:hover': {
                                                  backgroundColor: 'background.default',
                                              },
                                          }}
                                >
                                    <TableCell>
                                        {this.state.modified_logs.has(
                                            log.id!
                                        ) ? (
                                            <TextField
                                                variant="standard"
                                                value={log.version}
                                                onChange={(event) => {
                                                    const logs = this.props.logs
                                                    logs[index].version =
                                                        event.target.value
                                                    this.props.editLogs(logs)
                                                }}
                                            ></TextField>
                                        ) : (
                                            <>{log.version}</>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        {this.state.modified_logs.has(
                                            log.id!
                                        ) ? (
                                            <TextField
                                                variant="standard"
                                                value={log.date}
                                                onChange={(event) => {
                                                    const logs = this.props.logs
                                                    logs[index].date =
                                                        event.target.value
                                                    this.props.editLogs(logs)
                                                }}
                                            ></TextField>
                                        ) : (
                                            <>{log.date}</>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        {this.state.modified_logs.has(
                                            log.id!
                                        ) ? (
                                            <TextareaAutosize
                                                value={log.content}
                                                onChange={(event) => {
                                                    const logs = this.props.logs
                                                    logs[index].content =
                                                        event.target.value
                                                    this.props.editLogs(logs)
                                                }}
                                            ></TextareaAutosize>
                                        ) : (
                                            <>
                                                {log.content
                                                    .split('\n')
                                                    .map((content, index) => (
                                                        <p key={index}>
                                                            {content}
                                                        </p>
                                                    ))}
                                            </>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <List>
                                            <ListItem>
                                                <Tooltip
                                                    title="Détruire"
                                                    placement="right"
                                                >
                                                    <Button
                                                        sx={{p: 0}}
                                                        color="error"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            // TODO : déplacer dans une méthode de classe
                                                            const logs =
                                                                this.props.logs
                                                            logs.splice(
                                                                index,
                                                                1
                                                            )
                                                            this.props.editLogs(
                                                                logs
                                                            )
                                                            this.setState({
                                                                delete: true,
                                                                current_log:
                                                                log,
                                                            })
                                                        }}
                                                    >
                                                        <Icon
                                                            path={mdiDelete}
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            </ListItem>

                                            <ListItem>
                                                {!this.state.modified_logs.has(
                                                    log.id!
                                                ) ? (
                                                    <Tooltip
                                                        title="Éditer"
                                                        placement="right"
                                                    >
                                                        <Button
                                                            sx={{p: 0}}
                                                            variant="outlined"
                                                            onClick={() => {
                                                                this.addIdToModifiedLogs(
                                                                    log.id!
                                                                )
                                                            }}
                                                        >
                                                            <Icon
                                                                path={mdiPencil}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                        title="Enregistrer"
                                                        placement="right"
                                                    >
                                                        <Button
                                                            sx={{p: 0}}
                                                            variant="outlined"
                                                            onClick={() => {
                                                                log.id
                                                                    ? this.setState(
                                                                        {
                                                                            update: true,
                                                                            create: false,
                                                                            current_log:
                                                                            log,
                                                                        }
                                                                    )
                                                                    : this.setState(
                                                                        {
                                                                            update: false,
                                                                            create: true,
                                                                            current_log:
                                                                            log,
                                                                        }
                                                                    )

                                                                this.state.modified_logs.delete(
                                                                    log.id!
                                                                )
                                                            }}
                                                        >
                                                            <Icon
                                                                path={
                                                                    mdiContentSave
                                                                }
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </ListItem>
                                        </List>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.delete ? (
                    <Areyousure
                        message="Supprimer en base un log des versions d'algorithme. Les utilisateurs n'en seront plus notifiés"
                        close={() => {
                            this.setState({delete: false})
                        }}
                        action={async () => {
                            // TODO : créer une méthode fonction pour gérer les
                            await AlgosChangelogApi.updateAlgosChangelog(
                                this.props.logs
                            )
                        }}
                        reload={this.props.load}
                    />
                ) : (
                    <></>
                )}
                {this.state.create ? (
                    <Areyousure
                        message="Créer en base un nouveau log des versions d'algorithme."
                        close={() => {
                            this.setState({create: false})
                        }}
                        action={async () => {
                            // TODO : créer une méthode de classe pour gérer les erreurs
                            await AlgosChangelogApi.updateAlgosChangelog(
                                this.props.logs
                            )
                        }}
                        reload={this.props.load}
                    />
                ) : (
                    <></>
                )}
                {this.state.update ? (
                    <Areyousure
                        message="Modifier en base un log des versions d'algorithme."
                        close={() => {
                            this.setState({update: false})
                        }}
                        action={async () => {
                            // TODO : créer une méthode de classe pour gérer les erreurs
                            await AlgosChangelogApi.updateAlgosChangelog(
                                this.props.logs
                            )
                        }}
                        reload={this.props.load}
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}
