import React from "react";
import {Document, Page, Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer';
import {ListeEssences, ProblemeSanitaire, Project} from "../../interfaces/project_interface";
import {ClimateData} from "../../interfaces/climateData_interface";
import logo_bioclimsol from '../../assets/images/logo_bioclimsol.png'
import {SlopeUnitMapping} from "../../enums/SlopeUnit";
import {ConfinementUnitMapping} from "../../enums/ConfinementUnit";
import {TopographicPositionMapping} from "../../enums/TopographicPosition";
import {WaterSupplyMapping} from "../../enums/WaterSupply";
import {CompactnessMapping} from "../../enums/CompactnessValues";
import {ReasonStopAugerMapping} from "../../enums/ReasonStopAuger";
import {HumusMapping} from "../../enums/Humus";
import {TextureEnum, TextureKey} from "../../enums/Texture";
import {PropDeperisTreeMapping} from "../../enums/PropDeperisTree";
import {ResilienceMapping} from "../../enums/Resilience";
import {MajorIssueMapping} from "../../enums/MajorIssue";
import {SeverityEnumMapping} from "../../enums/Severity";
import IBSTablePDF from "./components/IBSTablePDF";
import INBTablePDF from "./components/INBTablePDF";
import PeuplementPDF from "./components/PeuplementPDF";
import DeperissementDiagramPDF from "./components/DeperissementDiagramPDF";
import {CircularProgress} from "@mui/material";
import {getSeverityPercentageLabel} from "../../utils/sanitaryLabels";

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
    fontWeight: 300,
});

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    fontWeight: 400,
});

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    fontWeight: 500,
});

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 700,
});


const styles = StyleSheet.create({
    page: {
        padding: 30,
        backgroundColor: '#ffffff'
    },
    header: {
        marginBottom: 20,
    },
    text: {
        fontSize: 10,
        marginBottom: 2,
        fontFamily: 'Roboto',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 6,
        fontFamily: 'Roboto',
    },
    listItem: {
        flexDirection: 'row',
        marginBottom: 2,
        fontSize: 10
    },
    bullet: {
        width: 10,
        fontSize: 10,
    },
    listItemText: {
        flex: 1,
        fontSize: 10
    },
    rowContainer: {
        flexDirection: 'row',
        marginBottom: 12
    },
    rowDiagnosticINB: {
        marginTop: 50
    },
    column: {
        flex: 1,
    },
    table: {
        display: 'flex',
        width: 'auto',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderColor: '#D6D6D6',
        marginBottom: 15,
        marginLeft: 10,
        marginRight: 10,
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#F5F5F5',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#D6D6D6',
    },
    tableHeaderCell: {
        flex: 1,
        padding: 5,
        borderRightWidth: 1,
        borderRightColor: '#D6D6D6',
        fontSize: 10,
    },
    tableCell: {
        flex: 1,
        padding: 5,
        borderRightWidth: 1,
        borderRightColor: '#D6D6D6',
        fontSize: 10,
    },
    containerFooter: {
        display: 'flex',
        margintop: 30,
    },
    footer: {
        alignItems: 'center',
    },
    textFooter: {
        fontSize: 9,
        color: 'grey',
        textAlign: 'center',
        margin: 2
    }
});

interface ProjectProps {
    project: Project | null;
    climateData: ClimateData | null;
}


const ProjectPDF = ({project, climateData}: ProjectProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {!project? (
                    <CircularProgress/>
                ) : (
                    <>
                {/* Header avec logo */}
                <View>
                    <Image
                        src={logo_bioclimsol}
                        style={{
                            paddingBottom: 10,
                            width: '50%',
                            height: 'auto',
                            display: 'flex',
                            alignSelf: 'flex-end'
                        }}
                    />
                </View>

                {/* Informations générales */}
                <View style={styles.rowContainer}>
                    <View style={styles.column}>
                        <Text style={styles.text}>
                            Généré le {
                            new Date().toLocaleString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            })
                        }
                        </Text>
                        <Text
                            style={styles.text}>Opérateur: {project.data.operator.replace(/^./, project.data.operator[0].toUpperCase())}</Text>
                        <Text
                            style={styles.text}>{project.data.commune.replace(/^./, project.data.commune[0].toUpperCase())}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.text}>Latitude : {project.data.lat}</Text>
                        <Text style={styles.text}>Longitude : {project.data.lon}</Text>
                        <Text
                            style={styles.text}>{project.shareWithCnpf ? 'Données partagées avec le CNPF' : 'Données non partagées avec le CNPF'}</Text>

                    </View>
                </View>

                {/* Contexte topographique */}
                <View style={styles.rowContainer}>
                    <View style={[styles.column, {flex: 0.5}]}>
                        <Text style={styles.title}>Contexte topographique</Text>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Altitude : {project.data.stationaryContext.altitude} m</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Exposition
                                : {project.data.stationaryContext.exposureValue && project.data.stationaryContext.exposureUnit
                                    ? `${project.data.stationaryContext.exposureValue} ${project.data.stationaryContext.exposureUnit}`
                                    : 'Aucune'}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Pente : {project.data.stationaryContext.slopeValue}
                                {SlopeUnitMapping[project.data.stationaryContext.slopeUnit as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Confinement : {project.data.stationaryContext.containmentValue}
                                {ConfinementUnitMapping[project.data.stationaryContext.containmentUnit as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Position topographique
                                : {TopographicPositionMapping[project.data.stationaryContext.topographicPosition as string]}
                            </Text>
                        </View>

                    </View>
                    <View style={[styles.column, {flex: 0.5}]}>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>TPI : {project.data.stationaryContext.tpi}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Alimentation en eau
                                : {WaterSupplyMapping[project.data.stationaryContext.watterSupply as string]}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Affleurements rocheux
                                : {project.data.stationaryContext.rockyOutcrops} %</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Présence de terrasse
                                : {project.data.stationaryContext.terracements ? 'Oui' : 'Non'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Commentaires : {project.data.stationaryContext.comment} </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.rowContainer}>
                    {/* Données climatiques */}
                    <View style={[styles.column, {flex: 0.6}]}>
                        <Text style={styles.title}>Données climatiques</Text>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Moyenne des températures annuelles (TMAN)
                                : {climateData?.TMAN ? `${climateData.TMAN} °C` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Moyenne des températures juin-août (TX0608)
                                : {climateData?.TX0608 ? `${climateData.TX0608} °C` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Moyenne des températures de janvier (TN01)
                                : {climateData?.TN01 ? `${climateData.TN01} °C` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Température minimale absolue (TNABS)
                                : {climateData?.TNABS ? `${climateData.TNABS} °C` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Précipitations annuelles
                                : {climateData?.Pan ? `${climateData.Pan} mm` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Précipitations avril-octobre
                                : {climateData?.P0410 ? `${climateData.P0410} mm` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>P-ETP (Turc) juin-août
                                : {climateData?.PETP0608 ? `${climateData.PETP0608} mm` : 'Non disponible'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>P-ETP (Turc) mai-septembre
                                : {climateData?.PETP0509 ? `${climateData.PETP0509} mm` : 'Non disponible'}</Text>
                        </View>
                    </View>

                    {/* Description du sol */}
                    <View style={[styles.column, {flex: 0.5}]}>
                        <Text style={styles.title}>Description du sol</Text>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Compacité
                                : {CompactnessMapping[project.data.pedology.compactness as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Cause arrêt tarière
                                : {ReasonStopAugerMapping[project.data.pedology.causeArretTariere as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Forme d'humus
                                : {HumusMapping[project.data.pedology.humus as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>PH à 20 cm
                                : {project.data.pedology.ph20} {project.data.pedology.phMeasured ? '(valeur mesurée)' : '(valeur non mesurée)'}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Réservoir utile en eau
                                : {project.data.pedology.usefulReserve != null && (
                                    `${project.data.pedology.usefulReserve.toFixed(2)} mm`
                                )}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>UCS
                                : {project.data.pedology.ucs}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Type de sol
                                : {project.data.pedology.groundType}

                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Nature de la roche
                                : {project.data.pedology.rockType}

                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Commentaires : {project.data.pedology.comment} </Text>
                        </View>
                    </View>
                </View>

                {/* Horizons */}
                <View>
                    <Text style={styles.title}>Horizons</Text>
                    {project.data.pedology.horizons && project.data.pedology.horizons.length > 0 ? (

                        <>
                            <View style={styles.table}>
                                <View style={styles.tableHeader}>
                                    <View style={styles.tableHeaderCell}>
                                        <Text style={{fontWeight: 'bold'}}>#</Text>
                                    </View>
                                    <View style={styles.tableHeaderCell}>
                                        <Text style={{fontWeight: 'bold'}}>Épaisseur</Text>
                                    </View>
                                    <View style={styles.tableHeaderCell}>
                                        <Text style={{fontWeight: 'bold'}}>Texture</Text>
                                    </View>
                                    <View style={styles.tableHeaderCell}>
                                        <Text style={{fontWeight: 'bold'}}>EG</Text>
                                    </View>
                                    <View style={styles.tableHeaderCell}>
                                        <Text style={{fontWeight: 'bold'}}>Calcaire actif</Text>
                                    </View>
                                    <View style={styles.tableHeaderCell}>
                                        <Text style={{fontWeight: 'bold'}}>Hydromorphie</Text>
                                    </View>
                                </View>
                                {project.data.pedology.horizons.map((horizons) => (
                                    <View key={horizons.num} style={styles.tableRow}>
                                        <View style={styles.tableCell}>
                                            <Text>{horizons.num}</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text>{horizons.thickness}cm</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text>{TextureEnum[horizons.texture as TextureKey]}</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text>{horizons.eg}%</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text>{horizons.effervescence ? 'OUI' : 'NON'}</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text>{horizons.hydromorphy}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </>
                    ) : (
                        <View style={styles.rowContainer}>
                            <Text style={styles.text}>Aucun horizon n'est enregistré</Text>
                        </View>
                    )}
                </View>

                {/* Observations sanitaires */}
                <View>
                    <Text style={styles.title}>Observations sanitaires</Text>
                    {project.data.sanitaryIssues?.listeProblemes && project.data.sanitaryIssues.listeProblemes.length > 0 ? (
                        <>
                            <View style={styles.rowContainer}>
                                <View style={styles.column}>
                                    <View style={styles.table}>
                                        <View style={styles.tableHeader}>
                                            <View style={styles.tableHeaderCell}>
                                                <Text style={{fontWeight: 'bold'}}>Problème</Text>
                                            </View>
                                            <View style={styles.tableHeaderCell}>
                                                <Text style={{fontWeight: 'bold'}}>Sévérité</Text>
                                            </View>
                                            <View style={styles.tableHeaderCell}>
                                                <Text style={{fontWeight: 'bold'}}>% de tiges atteintes</Text>
                                            </View>
                                        </View>
                                        {project.data.sanitaryIssues.listeProblemes
                                            .slice(0, Math.ceil(project.data.sanitaryIssues.listeProblemes.length / 2))
                                            .map((problem: ProblemeSanitaire) => (
                                                <View key={problem.problemeId} style={styles.tableRow}>
                                                    <View style={styles.tableCell}>
                                                        <Text> {problem.problemeLabel} </Text>
                                                    </View>
                                                    <View style={styles.tableCell}>
                                                        <Text> {getSeverityPercentageLabel(problem.severite)} </Text>
                                                    </View>
                                                    <View style={styles.tableCell}>
                                                        <Text>{problem.proportion}% </Text>
                                                    </View>
                                                </View>
                                            ))}
                                    </View>
                                </View>

                                <View style={styles.column}>
                                    <View style={styles.table}>
                                        <View style={styles.tableHeader}>
                                            <View style={styles.tableHeaderCell}>
                                                <Text style={{fontWeight: 'bold'}}>Problème</Text>
                                            </View>
                                            <View style={styles.tableHeaderCell}>
                                                <Text style={{fontWeight: 'bold'}}>Sévérité</Text>
                                            </View>
                                            <View style={styles.tableHeaderCell}>
                                                <Text style={{fontWeight: 'bold'}}>% de tiges atteintes</Text>
                                            </View>
                                        </View>
                                        {project.data.sanitaryIssues.listeProblemes
                                            .slice(Math.ceil(project.data.sanitaryIssues.listeProblemes.length / 2))
                                            .map((problem) => (
                                                <View key={problem.problemeId} style={styles.tableRow}>
                                                    <View style={styles.tableCell}>
                                                        <Text> {problem.problemeLabel} </Text>
                                                    </View>
                                                    <View style={styles.tableCell}>
                                                        <Text> {getSeverityPercentageLabel(problem.severite)} </Text>
                                                    </View>
                                                    <View style={styles.tableCell}>
                                                        <Text>{problem.proportion}% </Text>
                                                    </View>
                                                </View>
                                            ))}
                                    </View>
                                </View>
                            </View>
                            <View style={styles.rowContainer}>
                                <View style={styles.listItem}>
                                    <Text style={styles.bullet}> • </Text>
                                    <Text style={styles.text}>Commentaires
                                        : {project.data.sanitaryIssues.comment || ''}</Text>
                                </View>
                            </View>
                        </>
                    ) : (
                        <View style={styles.rowContainer}>
                            <Text style={styles.text}>Aucun problème sanitaire n'est enregistré</Text>
                        </View>
                    )}
                </View>

                {/* Description sylvicole */}
                <View style={styles.rowContainer}>
                    <View style={styles.column}>
                        <Text style={styles.title}>Description sylvicole</Text>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Essence : {
                                project.data.populationCharacteristics?.woodSpecies
                                    ?.replace(/_/g, ' ')
                                    ?.replace(/^./, char => char.toUpperCase()) ?? ''
                            }</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Type de peuplement : {
                                project.data.populationCharacteristics?.populationType
                                    ?.replace(/_/g, ' ')
                                    ?.replace(/^./, char => char.toUpperCase()) ?? ''
                            }</Text>
                        </View>

                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Hauteur dominante
                                : {project.data.populationCharacteristics.dominantHeight} m {project.data.populationCharacteristics.dominantHeightMeasured ? '(valeur mesurée)' : '(valeur non mesurée)'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Diamètre moyen
                                : {project.data.populationCharacteristics.averageDiameter} cm {project.data.populationCharacteristics.averageDiameterMeasured ? '(valeur mesurée)' : '(valeur non mesurée)'}</Text>
                        </View>

                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Surface Terrière
                                : {project.data.populationCharacteristics.basalArea} m²/ha {project.data.populationCharacteristics.basalAreaMesured ? '(valeur mesurée)' : '(valeur non mesurée)'}</Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Commentaires
                                : {project.data.populationCharacteristics.comment} </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.rowContainer} wrap={false}>
                    <View style={styles.column}>
                        <Text style={styles.title}>Liste des essences présentes :</Text>
                        {project.data.populationCharacteristics?.listeEssences ? (
                            <View style={styles.table}>
                                <>
                                    <View style={styles.tableHeader}>
                                        <View style={styles.tableHeaderCell}>
                                            <Text style={{fontWeight: 'bold'}}>Essence</Text>
                                        </View>
                                        <View style={styles.tableHeaderCell}>
                                            <Text style={{fontWeight: 'bold'}}>Diamètre moyen (cm)</Text>
                                        </View>
                                        <View style={styles.tableHeaderCell}>
                                            <Text style={{fontWeight: 'bold'}}>Hauteur dominante (m)</Text>
                                        </View>
                                        <View style={styles.tableHeaderCell}>
                                            <Text style={{fontWeight: 'bold'}}>Age (ans)</Text>
                                        </View>
                                        <View style={styles.tableHeaderCell}>
                                            <Text style={{fontWeight: 'bold'}}>G (m²/ha)</Text>
                                        </View>
                                    </View>
                                    {project.data.populationCharacteristics?.listeEssences.map((essence: ListeEssences) => (
                                        <View key={essence.id} style={styles.tableRow}>
                                            <View style={styles.tableCell}>
                                                <Text>{essence.essenceLabel}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text>{essence.diametreMoyen}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text>{essence.hauteurDominante}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text>{essence.ageMoyen}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                <Text>{essence.surfaceTerriere}</Text>
                                            </View>
                                        </View>
                                    ))}
                                </>
                            </View>
                        ) : (
                            <Text style={styles.text}>Aucune essence n'est enregistrée</Text>
                        )}
                    </View>
                </View>

                {/* Dépérissement */}
                <View style={styles.rowContainer}>
                    <View style={styles.column}>
                        <Text style={styles.title}>Dépérissement</Text>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Estimation de la proportion d'arbres dépérissants sur
                                la parcelle
                                : {PropDeperisTreeMapping[project.data.dieback.parcelFrequency as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Présence de résilience
                                : {ResilienceMapping[project.data.dieback.parcelIntensity as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Problème majeur récent (5ans)
                                : {MajorIssueMapping[project.data.dieback.environmentFrequency as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Sévérité du problème
                                : {SeverityEnumMapping[project.data.dieback.environmentIntensity as string]}
                            </Text>
                        </View>
                        <View style={styles.listItem}>
                            <Text style={styles.bullet}> • </Text>
                            <Text style={styles.text}>Commentaires
                                : {project.data.dieback.comment}
                            </Text>
                        </View>
                    </View>
                </View>

                {/* Diagramme deperissement */}
                {project.data.dieback.tree && project.data.dieback.tree.length > 0
                    ? <DeperissementDiagramPDF data={project.data.dieback.tree}/>
                    : null
                }

                {/* Diagnostic peuplement */}
                {project?.diagnostic?.peuplement && (
                    <View style={styles.rowDiagnosticINB}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.title}>Diagnostic peuplement</Text>
                        </View>
                        <View style={styles.rowContainer}>
                            <PeuplementPDF diagnosticsPeuplement={project.diagnostic.peuplement}/>
                        </View>
                    </View>
                )}

                {/* Diagnostic Essences IBS */}
                {project?.diagnostic?.boisement?.diagnosticsIBS && (
                    <View style={styles.rowDiagnosticINB}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.title}>
                                Solutions de renouvellement - Essences IBS
                            </Text>
                        </View>
                        <View style={styles.rowContainer}>
                            <IBSTablePDF
                                diagnosticsEssenceIBS={project.diagnostic.boisement.diagnosticsIBS}/>
                        </View>
                    </View>
                )}

                {/* Diagnostic Essences INB */}
                {project?.diagnostic?.boisement?.diagnosticsNonIBS && (
                    <View style={styles.rowDiagnosticINB}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.title}>
                                Solutions de renouvellement - Essences INB
                            </Text>
                        </View>
                        <View style={styles.rowContainer}>
                            <INBTablePDF
                                diagnosticsEssenceNonIBS={project.diagnostic.boisement.diagnosticsNonIBS}/>
                        </View>
                    </View>
                )}

                {/* Footer */}
                <View style={styles.containerFooter}>
                    <View style={styles.footer}>
                        <Text style={styles.textFooter}> Généré par Bioclimsol </Text>
                        <Text style={styles.textFooter}> Version générateur 0.4 </Text>
                    </View>
                </View>
            </>
            )}
            </Page>
        </Document>
    );
};
export default ProjectPDF;
