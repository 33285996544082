import React from 'react';
import {Box} from '@mui/material';
import logo_bioclimsol from '../../assets/images/logo_bioclimsol_solo.png'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface AppHeaderProps {
    title: string;
}

const AppHeader: React.FC<AppHeaderProps> = ({title}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box sx={{
            mb: isMobile ? 3 : 5
        }}>
            <h2 style={{
                fontSize: isMobile ? '1rem' : '1.8rem'
            }}>{title}</h2>

            <Box sx={{
                mt: isMobile ? 0.5 : 1,
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                borderBottom: '1px solid #E0E0E0',
            }}>
                <Box sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    px: isMobile ? 0.5 : 1,
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'flex-end',
                    transform: isMobile ? 'translateY(25%)' : 'translateY(30%)'
                }}>
                    <img
                        src={logo_bioclimsol}
                        alt="Logo de BioClimSol"
                        style={{
                            width: 'auto',
                            height: isMobile ? '35px' : '50px',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AppHeader;