import {useParams} from 'react-router-dom';
import React from "react";
import {ProjectApi} from "../../api/projects_api";
import IBSTable from "./components/IBSTable";
import INBTable from "./components/INBTable";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {Project} from "../../interfaces/project_interface";
import Peuplement from "./components/Peuplement";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function DiagnosticDetailsMiddleware(): React.ReactElement {
    const params = useParams<{ id: string }>();
    return <DiagnosticDetails params={params}/>
}

export interface DiagnosticDetailsProps {
    params: {
        id?: string;
    }
}

interface DiagnosticDetailsState {
    diagnostic: Project | null;
    loading: boolean;
    selectedTab: number;
}

export class DiagnosticDetails extends React.Component<DiagnosticDetailsProps, DiagnosticDetailsState> {
    constructor(props: DiagnosticDetailsProps) {
        super(props);
        this.state = {
            diagnostic: null,
            loading: true,
            selectedTab: 0
        };
    }


    async componentDidMount() {
        const projectId = this.props.params.id;
        if (projectId) {
            try {
                const project = await ProjectApi.getProject(parseInt(projectId));
                this.setState({
                    diagnostic: project,
                    loading: false
                });


            } catch (error) {
                console.error("Erreur lors du chargement du projet:", error);
                this.setState({loading: false});
            }
        }
    }

    handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({selectedTab: newValue});
    };

    render() {
        const {diagnostic, loading, selectedTab} = this.state;

        if (loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress/>
                </Box>
            );
        }

        if (!diagnostic?.diagnostic?.boisement) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Typography color="text.secondary">Diagnostic non disponible</Typography>
                </Box>
            );
        }

        return (
            <Box sx={{p: 2}}>
                <Accordion
                    defaultExpanded
                    disableGutters
                    sx={{
                        boxShadow: 'none',
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            backgroundColor: "background.default",
                            '& .MuiAccordionSummary-content': {
                                justifyContent: 'center'
                            },
                            '& .Mui-expanded': {
                                margin: '0'
                            }
                        }}
                    >
                        <Typography variant="h5" component="h1">
                            Diagnostic boisement
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 0}}>
                        <Box sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Tabs
                                value={selectedTab}
                                onChange={this.handleTabChange}
                                variant="fullWidth"
                                sx={{
                                    width: '100%',
                                    '& .MuiTab-root': {
                                        fontWeight: 500,
                                        fontSize: '16px',
                                        minWidth: '200px',
                                    }
                                }}
                            >
                                <Tab label="IBS"/>
                                <Tab label="INB"/>
                            </Tabs>
                        </Box>

                        {selectedTab === 0 ? (
                            <Box sx={{p: 3}}>
                                {diagnostic.diagnostic.boisement.diagnosticsIBS ? (
                                    <IBSTable diagnosticsEssenceIBS={diagnostic.diagnostic.boisement.diagnosticsIBS}/>
                                ) : (
                                    <Typography color="text.secondary">
                                        Aucune essence IBS disponible
                                    </Typography>
                                )}
                            </Box>
                        ) : (
                            <Box sx={{p: 3}}>
                                {diagnostic.diagnostic.boisement.diagnosticsNonIBS ? (
                                    <INBTable
                                        diagnosticsEssenceNonIBS={diagnostic.diagnostic.boisement.diagnosticsNonIBS}/>
                                ) : (
                                    <Typography color="text.secondary">
                                        Aucune essence INB disponible
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion disableGutters
                           sx={{
                               boxShadow: 'none',
                               mt: 2,
                               '&:before': {
                                   display: 'none',
                               },
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            backgroundColor: "background.default",
                            '& .MuiAccordionSummary-content': {
                                justifyContent: 'center'
                            }
                        }}
                    >
                        <Typography variant="h5" component="h1">
                            Diagnostic peuplement sur pied
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {diagnostic.diagnostic.peuplement ? (
                            <Peuplement diagnosticsPeuplement={diagnostic.diagnostic.peuplement}/>
                        ) : (
                            <Typography color="text.secondary">
                                Aucun diagnostic peuplement disponible
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    }
}

export default DiagnosticDetails;