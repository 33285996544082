import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { VigilenceColors } from '../../../assets/styles/colors';
import { DiagnosticPeuplement, DiagnosticTemperature } from "../../../interfaces/Diagnostic_interface";
import React from "react";

interface PeuplementProps {
    diagnosticsPeuplement: DiagnosticPeuplement;
}

interface CircleProps {
    value: number;
    isDeperissement?: boolean;
    label: string;
}

interface TemperatureSectionProps {
    temperature: DiagnosticTemperature;
    label: string;
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        padding: 20,
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 10,
    },
    mainContent: {
        border: '1pt solid #e0e0e0',
    },

    temperatureSection: {
        borderBottom: '1pt solid #e0e0e0',
    },
    temperatureHeader: {
        backgroundColor: '#f5f5f5',
        padding: 8,
    },
    temperatureTitle: {
        fontSize: 12,
        textAlign: 'center',
    },
    vigilenceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 15,
    },
    circleContainer: {
        alignItems: 'center',
    },
    circle: {
        width: 60,
        height: 60,
        borderRadius: 30,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5,
    },
    circleText: {
        color: '#ffffff',
        fontSize: 14,
    },
    circleLabel: {
        fontSize: 10,
        textAlign: 'center',
    },
    fiabiliteText: {
        fontSize: 10,
        textAlign: 'center',
        padding: 8,
    },
});

const getIndexColorDieback = (percent: number): number => {
    switch(true) {
        case percent >= 95: return 10;
        case percent >= 90: return 9;
        case percent >= 80: return 8;
        case percent >= 70: return 7;
        case percent >= 60: return 6;
        case percent >= 50: return 5;
        case percent >= 40: return 4;
        case percent >= 30: return 3;
        case percent >= 20: return 2;
        case percent >= 10: return 1;
        default: return 0;
    }
};

const VigilenceCirclePdf: React.FC<CircleProps> = ({value, isDeperissement = false, label}) => {
    const colorIndex = isDeperissement ? getIndexColorDieback(value) : value;

    return (
        <View style={styles.circleContainer}>
            <View style={[styles.circle, { backgroundColor: VigilenceColors.getVigilenceColor(colorIndex) }]}>
                <Text style={styles.circleText}>
                    {isDeperissement ? `${value}%` : value}
                </Text>
            </View>
            <Text style={styles.circleLabel}>{label}</Text>
        </View>
    );
};

const TemperatureSectionPdf: React.FC<TemperatureSectionProps> = ({temperature, label}) => {
    return (
        <View style={styles.temperatureSection}>
            <View style={styles.temperatureHeader}>
                <Text style={styles.temperatureTitle}>{label}</Text>
            </View>

            <View style={styles.vigilenceContainer}>
                <VigilenceCirclePdf
                    value={temperature.vigilence}
                    label="Vigilance"
                />
                <VigilenceCirclePdf
                    value={temperature.deperissement}
                    isDeperissement={true}
                    label="Risque de dépérissement"
                />
            </View>

            <Text style={styles.fiabiliteText}>
                Fiabilité du modèle: {temperature.fiabilite}
            </Text>
        </View>
    );
};

const PeuplementPDF: React.FC<PeuplementProps> = ({diagnosticsPeuplement}) => {
    const formatEssence = (essence: string): string => {
        return essence.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>
                {formatEssence(diagnosticsPeuplement.essence)}
            </Text>

            <View style={styles.mainContent}>
                <TemperatureSectionPdf
                    temperature={diagnosticsPeuplement.temperature[0]}
                    label="Température actuelle"
                />

                <TemperatureSectionPdf
                    temperature={diagnosticsPeuplement.temperature[1]}
                    label="Température +1°C"
                />

                <TemperatureSectionPdf
                    temperature={diagnosticsPeuplement.temperature[2]}
                    label="Température +2°C"
                />
            </View>
        </View>
    );
};

export default PeuplementPDF;