export const MajorIssueEnum = {
    ZERO: 'Non identifié',
    ONE: 'Gui',
    TWO: 'Défoliateur',
    THREE: 'Scolyte(s)',
    FOUR: 'Cynips',
    FIVE: 'Autres insectes',
    SIX: 'Chancre',
    SEVEN: 'Encre',
    EIGHT: 'Oïdium',
    NINE: 'Autres champignons',
    TEN: 'Décoloration',
    ELEVEN: 'Nécroses',
}

export const MajorIssueMapping: { [key: string]: string } = {
    '0': MajorIssueEnum.ZERO,
    '1': MajorIssueEnum.ONE,
    '2': MajorIssueEnum.TWO,
    '3': MajorIssueEnum.THREE,
    '4': MajorIssueEnum.FOUR,
    '5': MajorIssueEnum.FIVE,
    '6': MajorIssueEnum.SIX,
    '7': MajorIssueEnum.SEVEN,
    '8': MajorIssueEnum.EIGHT,
    '9': MajorIssueEnum.NINE,
    '10': MajorIssueEnum.TEN,
    '11': MajorIssueEnum.ELEVEN,
}