export const INBColor = {
    vert: '#008000',
    jaune: '#FFA500',
    rouge: '#FF0000',
    gris: '#929292'
} as const;

export const getBorderColor = (climatique: string, pedologique: string, topographique: string): string => {
    if (topographique === INBColor.rouge ||
        pedologique === INBColor.rouge ||
        climatique === INBColor.rouge) {
        return INBColor.rouge;
    }

    if (topographique === INBColor.jaune ||
        climatique === INBColor.jaune ||
        pedologique === INBColor.jaune) {
        return INBColor.jaune;
    }

    return INBColor.vert;
};

export const getIconColor = (color: string): string => {
    if (color === INBColor.jaune) {
        return INBColor.jaune;
    }
   if (color === INBColor.rouge) {
        return INBColor.rouge;
    }
   if (color === INBColor.vert) {
        return INBColor.vert;
    }
    return INBColor.gris;
}