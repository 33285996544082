// TODO : ce fichier est-il vraiment dans le bon dossier ?

export class AuthToken {
    /**
     * Récupérer le JWT d'authentification pour effectuer une requête sur le backend
     */
    static get(): string {
        const data = localStorage.getItem('userToken')
        if (data === null) {
            throw new Error('Le token est absent du stockage')
        }
        try {
            const item = JSON.parse(data)
            if (Date.now() <= item.expiry) {
                return item.value
            }
        } catch (e) {
            localStorage.removeItem('userToken')
            window.location.reload()
            return ''
        }
        localStorage.removeItem('userToken')
        window.location.reload()
        return ''
    }

    /**
     * Définir le JWT d'authentification
     */
    static set(token: string, ttl: number = 86400): void {
        const item = {
            value: token,
            expiry: Date.now() + ttl * 1000,
        }

        localStorage.setItem('userToken', JSON.stringify(item))
    }

    /**
     * Vérifie si un jeton d'authentification est disponible
     */
    // TODO : renommer en isPresent
    static has(): boolean {
        return localStorage.getItem('userToken') !== null
    }
}
