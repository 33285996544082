export const TopographicPositionEnum = {
    PLAINE: "Plaine",
    PLATEAU: "Plateau",
    HAUT_VERSANT: "Haut de versant",
    MILIEU_VERSANT: "Milieu de versant",
    BAS_VERSANT: "Bas de versant",
    VALLON: "Vallon",
    DEPRESSION: "Dépression",
    REPLAT: "Replat",
    SOMMET: "Sommet, croupe"
};

export const TopographicPositionMapping: { [key: string]: string } = {
    'plaine': TopographicPositionEnum.PLAINE,
    'plateau': TopographicPositionEnum.PLATEAU,
    'haut_versant': TopographicPositionEnum.HAUT_VERSANT,
    'milieu_versant': TopographicPositionEnum.MILIEU_VERSANT,
    'bas_versant': TopographicPositionEnum.BAS_VERSANT,
    'vallon': TopographicPositionEnum.VALLON,
    'depression': TopographicPositionEnum.DEPRESSION,
    'replat': TopographicPositionEnum.REPLAT,
    'sommet': TopographicPositionEnum.SOMMET
};
