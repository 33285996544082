import {Project} from "../../interfaces/project_interface";
import React from "react";
import {
    Box, Button, List, ListItem, Pagination, PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Icon from "@mdi/react";
import {mdiDelete, mdiEyeOutline} from "@mdi/js";
import {Link} from "react-router-dom";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";
import {ProjectApi, ProjectResponse} from "../../api/projects_api";
import {ProjectsFilter} from "./ProjectsFilter";
import AppHeader from "../../components/common/AppHeader";


export interface ProjectsProps {
    current_user: number
}

interface ProjectState {
    project: Project[]
    action_user_id: number
    delete: boolean
    filteredProjects: Project[]
    pagination?: {
        page: number
        limit: number
        totalItems: number
        totalPages: number
    }
}

export class Projects extends React.Component<ProjectsProps, ProjectState> {
    constructor(props: ProjectsProps) {
        super(props)
        this.state = {
            project: [],
            action_user_id: NaN,
            delete: false,
            filteredProjects: [],
            pagination: {
                page: 1,
                limit: 25,
                totalItems: 0,
                totalPages: 0
            }
        }
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            const response = await ProjectApi.listUserProjects();
            this.setState({
                project: response.data,
                pagination: response.pagination
            })
        } catch (e) {
            console.error(e)
            alert("Echec du chargement des projets")
        }
    }

    decodeString(input: any) {
        if (!input) return input;
        return input.replace(/\\u0027/g, "'").replace(/\\u0022/g, "\"");
    }

    handleSearch = (response: ProjectResponse) => {
        this.setState({
            project: response.data,
            pagination: response.pagination
        });
    }

    handlePageChange = async (page: number) => {
        try {
            const response = await ProjectApi.listUserProjects({
                ...this.state.filteredProjects,
                page: page
            });

            this.setState((prevState) => ({
                project: response.data,
                pagination: response.pagination
            }));
        } catch (e) {
            console.error(e);
            alert("Erreur lors du changement de page");
        }
    };


    render() {
        return (
            <Box sx={{p: 5}}>
                <AppHeader title="Mes projets" />
                <ProjectsFilter onSearch={this.handleSearch}/>
                <Box>
                    <TableContainer component={Paper} sx={{marginTop: 5}}>
                        <Table>
                            <TableHead
                                sx={{
                                    backdropFilter: 2,
                                    backgroundColor: 'primary.main',
                                }}
                            >
                                <TableRow>
                                    <TableCell sx={{color: '#ffffff'}}>
                                        Nom du projet
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff'}}>
                                        Nom de la forêt
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff'}}>
                                        Commune
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                        Dernière mise à jour
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                        État
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                        Diagnostic
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!(this.state.project.length) ? (
                                    <TableRow>
                                        <TableCell sx={{py: 2}}  colSpan={7} >
                                            Aucun projet trouvé !
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.project
                                        .sort((a, b) => a.timeUpdate < b.timeUpdate ? 1 : -1)
                                        .map((project: any) => (
                                            <TableRow
                                                key={project.id}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'background.default',
                                                    },
                                                }}
                                            >
                                                <TableCell sx={{py: 2}}>
                                                    {this.decodeString(project.data.name)}
                                                </TableCell>
                                                <TableCell sx={{py: 2}}>
                                                    {this.decodeString(project.data.forestName)}
                                                </TableCell>
                                                <TableCell sx={{py: 2}}>
                                                    {this.decodeString(project.data.commune)}
                                                </TableCell>
                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                    {new Date(
                                                        project.timeUpdate * 1000
                                                    ).toLocaleDateString()}
                                                </TableCell>
                                                {(project.data.isClosed) ? (
                                                    <TableCell sx={{py: 2, color: '#ff4524', textAlign: 'center'}}>
                                                        Fermé
                                                    </TableCell>
                                                ) : (
                                                    <TableCell sx={{py: 2, textAlign: 'center'}}>
                                                        Ouvert
                                                    </TableCell>
                                                )}
                                                <TableCell sx={{py: 2, textAlign: 'center'}}>
                                                    <Link to={`${project.id}/diagnostic`}
                                                          style={{
                                                              textDecoration: 'none',
                                                              color: 'inherit'
                                                          }}>
                                                        Voir
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{py: 0, display: 'flex', justifyContent: 'center'}}>
                                                    <Box>
                                                        <List>
                                                            <ListItem sx={{p: 0, my: 1}}>
                                                                <Tooltip
                                                                    title="Supprimer"
                                                                    placement="right"
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                action_user_id:
                                                                                project.id,
                                                                                delete: true,
                                                                            })
                                                                        }}
                                                                        sx={{p: 0, px: 1}}
                                                                        color="error"
                                                                    >
                                                                        <Icon
                                                                            path={mdiDelete}
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                            </ListItem>
                                                            <ListItem sx={{p: 0, my: 1}}>
                                                                <Link
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    to={`${project.id}`}
                                                                >
                                                                    <Tooltip
                                                                        title="Détails"
                                                                        placement="right"
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            sx={{p: 0}}
                                                                        >
                                                                            <Icon
                                                                                path={
                                                                                    mdiEyeOutline
                                                                                }
                                                                            />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Link>
                                                            </ListItem>
                                                            <ListItem sx={{p: 0}}>
                                                                <Link to={`${project.id}/pdf`}>
                                                                    <Tooltip title="PDF" placement="right">
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            sx={{
                                                                                textTransform: 'none',
                                                                                p: 0
                                                                            }}
                                                                        >
                                                                            PDF
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Link>
                                                            </ListItem>
                                                        </List>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>)))}
                            </TableBody>
                        </Table>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 2
                        }}>
                            <Pagination
                                count={this.state.pagination?.totalPages || 1}
                                page={this.state.pagination?.page || 1}
                                onChange={(event, page) => this.handlePageChange(page)}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{
                                            previous: ArrowBackIcon,
                                            next: ArrowForwardIcon
                                        }}
                                        {...item}
                                    />
                                )}
                            />
                        </Box>
                    </TableContainer>
                    {this.state.delete ? (
                        <Areyousure
                            message="supprimer un projet"
                            action={async () => {
                                await ProjectApi.deleteProjetUser(this.state.action_user_id)
                            }}
                            close={() => {
                                this.setState({delete: false})
                            }}
                            reload={() => this.load()}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        )
    }
}