import {get_restricted_url, get_unrestricted_url} from "../global_variables/backend_url_api";
import {Project} from "../interfaces/project_interface";
import {ApiClient} from "./api_client";
import {AuthToken} from "./token_api";
import {ClimateData} from "../interfaces/climateData_interface";
import {ProjectFilter} from "../routes/projets/ProjectsFilter";

export interface ProjectResponse {
    data: Project[];
    pagination: {
        page: number;
        limit: number;
        totalItems: number;
        totalPages: number;
    }
}

export class ProjectApi {
    static async listUserProjects(filters?: ProjectFilter): Promise<ProjectResponse> {
        const params = {
            restricted: false,
            params: {
                name: filters?.name,
                commune: filters?.commune,
                creationDate: filters?.creationDate,
                updatedDate: filters?.updatedDate,
                page: filters?.page
            }
        };
        return await ApiClient.get('/projects', params);
    }

    static async allProjects(): Promise<Project[]> {
        return await ApiClient.get('/projects/all')
    }

    static async getProject(id: number): Promise<Project> {
        return await ApiClient.get(`/projects/user/${id}`, {restricted: false})
    }

    static async projectData(
        id: number,
    ): Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open(
                'GET',
                `${get_unrestricted_url()}/projects/${id}`
            )
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error("Echec du téléchargement du projet"))
                }
            }

            request.send()
        })
    }

    static async usersProjectsCsv(): Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open("GET", `${get_unrestricted_url()}/projects/export`)
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error("Echec du téléchargement des projets"))
                }
            }
            request.send()
        })
    }

    static async AllProjectsCsv():
        Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open("GET", `${get_restricted_url()}/projects/export/all`)
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error("Echec du téléchargement des projets"))
                }
            }
            request.send()
        })
    }

    static async deleteProjetUser(projetId: number): Promise<void> {
        try {
            await ApiClient.delete(`/projets/delete/${projetId}`, {
                restricted: false
            })
        } catch (e) {
            throw new Error(
                `Echec de la suppression du projet`
            )
        }
    }

    /**
     * Récupère les données climatiques pour un projet spécifique
     * @param projectId - L'ID du projet
     * @returns ClimateData - Les données climatiques traitées
     */
    static async getClimateData(projectId: number): Promise<ClimateData | null> {
        try {
            const response = await ApiClient.get(`/maps/rasters/climate-data/${projectId}`, {restricted: false});
            if (!response) return null;
            return {
                TMAN: response.TMAN,
                TX0608: response.TX0608,
                TN01: response.TN01,
                TNABS: response.TNABS,
                Pan: response.Pan,
                P0410: response.P0410,
                ETPan: response.ETPan,
                PETP0608: response.PETP0608,
                PETP0509: response.PETP0509
            };
        } catch (error) {
            console.error("Échec du chargement des données climatiques:", error);
            throw error;
        }
    }
}