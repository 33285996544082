export const TextureEnum = {
    AL: "Argile limoneuse",
    ALS: "Argile limono-sableuse",
    ALo: "Argile lourde",
    AS: "Argile sableuse",
    LA: "Limon argileux",
    LAS: "Limon argilo-sableux",
    Ll: "Limon léger",
    LlS: "Limon léger sableux",
    Lm: "Limon moyen",
    LmS: "Limon moyen sableux",
    LS: "Limon sableux",
    LSA: "Limon sablo-argileux",
    S: "Sable",
    SA: "Sable argileux",
    SL: "Sable limoneux"
} as const;

export type TextureKey = keyof typeof TextureEnum;