export const WaterSupplyEnum = {
    PERTE_APPORT: "Pertes > apports",
    PERTE_EGAL_APPORT: "Pertes ≈ apports",
    APPORT_PERTE: "Apports > pertes",
    APPORT_GRAND_PERTE: "Apports >> pertes",
    NAPPE_COURANTE: "Nappe courante"
};

export const WaterSupplyMapping: { [key: string]: string } = {
    "perte\u2248apport": WaterSupplyEnum.PERTE_EGAL_APPORT,
    "perte>apport": WaterSupplyEnum.PERTE_APPORT,
    "apport>perte": WaterSupplyEnum.APPORT_PERTE,
    "apport>>perte": WaterSupplyEnum.APPORT_GRAND_PERTE,
    "nappe_courante": WaterSupplyEnum.NAPPE_COURANTE
};
