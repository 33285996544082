export const severityLabels: { [key: string]: string } = {
    "non_estimee": "Non estimée",
    "non_estime": "Non estimée",
    "nulle": "Nulle",
    "faible": "Faible",
    "moyenne": "Moyenne",
    "severe": "Sévère",
    "tres_severe": "Très sévère",
};

export const affectedStemsLabels: { [key: string]: string } = {
    "non_estimee": "Non estimé",
    "non_estime": "Non estimé",
    "0-5": "<5%",
    "6-25": "6 à 25%",
    "26-50": "26 à 50%",
    "51-75": "51 à 75%",
    "76-95": "76 à 95%",
    "96-100": ">95%",
};

export const severityPercentageLabels: { [key: string]: string } = {
    "non_estimee": "Non estimée",
    "non_estime": "Non estimée",
    "nulle": "<5%",
    "faible": "5 à 25%",
    "moyenne": "26 à 50%",
    "severe": "51 à 75%",
    "tres_severe": ">75%",
};

export const getSeverityLabel = (severityKey: string | undefined): string => {
    if (!severityKey) return "Non estimée";
    return severityLabels[severityKey] || "Non estimée";
};

export const getAffectedStemsLabel = (affectedStemsKey: string | undefined): string => {
    if (!affectedStemsKey) return "Non estimé";
    return affectedStemsLabels[affectedStemsKey] || "Non estimé";
};

export const getSeverityPercentageLabel = (severityKey: string | undefined): string => {
    if (!severityKey) return "Non estimée";
    return severityPercentageLabels[severityKey] || "Non estimée";
};