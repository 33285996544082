import {TemperatureNonIBS} from "../../../../interfaces/Diagnostic_interface";

export const getValueColor = (color: string): number => {
    switch (color) {
        case '#008000':
            return 1; // Vert
        case '#FFA500':
            return 2; // Orange
        case '#FF0000':
            return 3; // Rouge
        default:
            return 0;
    }
};

export const getSumTemperature = (temperature: TemperatureNonIBS[]): number => {
    return temperature.reduce((sum, temp) => (
        sum +
        getValueColor(temp.climatique.color) +
        getValueColor(temp.pedologique.color) +
        getValueColor(temp.topographique.color)
    ), 0);
};

export const formatEssence = (essence: string): string =>
    essence.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

export const headers = ["", "Actuel", "+1°C", "+2°C"];

