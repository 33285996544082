import React from 'react';
import {DiagnosticEssenceIBS} from "../../../interfaces/Diagnostic_interface";
import {sortByVigilence, VigilenceCircle} from "./common/vigilenceHelpers";
import {formatEssence} from "./common/INBHelpers";
import BaseTable from "./common/BaseTable";

const IBSTable: React.FC<{ diagnosticsEssenceIBS: DiagnosticEssenceIBS[] }> = ({diagnosticsEssenceIBS}) => {
    const sortedData = sortByVigilence(diagnosticsEssenceIBS);

    const renderCell = (diagnostic: DiagnosticEssenceIBS, columnIndex: number) => {
        if (columnIndex === 0) {
            return formatEssence(diagnostic.essence);
        }
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <VigilenceCircle vigilence={diagnostic.temperature[columnIndex - 1].vigilence}/>
            </div>
        );
    };

    return <BaseTable data={sortedData} renderCell={renderCell} />;
};

export default IBSTable;