import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup, Pagination, PaginationItem, TextField,
} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import {StructureApi} from '../../api/structures_api'
import {UserApi} from '../../api/users_api'
import {SearchInput} from '../../components/Inputs/SearchInput/SearchInput'
import {SelectInput} from '../../components/Inputs/SelectInput/SelectInput'
import {UserTable} from './user_table'
import {User} from '../../interfaces/user_interface'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AppHeader from "../../components/common/AppHeader";

export interface UsersProps {
}

export interface UsersFilter {
    firstname?: string
    lastname?: string
    email?: string
    isActive?: boolean
    isCnpf?: boolean
    isAdmin?: boolean
    structureId?: number
    expireIn?: number
    lastUsed?: number
    page?: number
}


interface Userstate {
    users: User[]
    structures: any[]
    filters: UsersFilter
    isAdmin: boolean | null
    isCnpf: boolean | null
    isActive: boolean | null
    is_loaded: boolean
    pagination: {
        page: number
        limit: number
        totalItems: number
        totalPages: number
    }
}

export class Users extends React.Component<UsersProps, Userstate> {
    constructor(props: UsersProps) {
        super(props)
        this.state = {
            users: [],
            structures: [],
            filters: {},
            isAdmin: null,
            isCnpf: null,
            isActive: true,
            is_loaded: false,
            pagination: {
                page: 1,
                limit: 25,
                totalItems: 0,
                totalPages: 0
            }
        }
        this.getStructures = this.getStructures.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const response = await UserApi.listUsers(this.state.filters)
            const structures = await this.getStructures()

            this.setState({
                users: response.data,
                structures: structures,
                pagination: response.pagination,
                is_loaded: true
            })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement de la liste des utilisateurs')
        }
    }

    async getStructures() {
        const structureList = await StructureApi.listStructures()
        return structureList.map((structure: any) => {
            return {name: structure.name, value: structure.id}
        })
    }


    updateFilters = (newFilters: Partial<UsersFilter>) => {
        const shouldResetPage = !('page' in newFilters);

        this.setState(
            prevState => ({
                filters: {
                    ...prevState.filters,
                    ...newFilters,
                    page: shouldResetPage ? 1 : newFilters.page
                }
            }),
            () => this.load()
        )
    }

    handlePageChange = async (page: number) => {
        this.updateFilters({page})
    }

    render() {
        return (
            <Box sx={{p: 5}}>
                <AppHeader title="Gestion des utilisateurs" />
                <Box>
                    <Link to="create">
                        <Button variant="outlined">
                            <span>Ajouter un utilisateur</span>
                        </Button>
                    </Link>
                </Box>
                <Box
                    display="flex"
                    sx={{
                        py: 4,
                        flexDirection: {
                            xs: 'column',
                            sm: 'row'
                        }
                    }}
                >
                    {[
                        {
                            label: 'Prénom',
                            type: 'text',
                            changeValue: (firstname: string) => {
                                this.updateFilters({firstname})
                            },
                        },
                        {
                            label: 'Nom',
                            type: 'text',
                            changeValue: (lastname: string) => {
                                this.updateFilters({lastname})
                            },
                        },
                        {
                            label: 'Email',
                            type: 'text',
                            changeValue: (email: string) => {
                                this.updateFilters({email})
                            },
                        },
                    ].map((element, index) => (
                        <SearchInput
                            label={element.label}
                            type={element.type}
                            changeValue={element.changeValue}
                            key={index}
                        />
                    ))}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Dernière connexion avant le"
                            value={this.state.filters.lastUsed ? dayjs.unix(this.state.filters.lastUsed) : null}
                            onChange={(newValue: any) => {
                                if (newValue && dayjs.isDayjs(newValue)) {
                                    const endOfDay = newValue.endOf('day');
                                    this.updateFilters({
                                        lastUsed: endOfDay.unix()
                                    });
                                } else {
                                    this.updateFilters({
                                        lastUsed: undefined
                                    });
                                }
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                        mx: {
                                            xs: 0,
                                            sm: 2
                                        }
                                    }}/>
                            )}
                        />

                        <DesktopDatePicker
                            label="Licences expirant avant le"
                            value={this.state.filters.expireIn ? dayjs.unix(this.state.filters.expireIn) : null}
                            onChange={(newValue: any) => {
                                if (newValue && dayjs.isDayjs(newValue)) {
                                    const endOfDay = newValue.endOf('day');
                                    this.updateFilters({
                                        expireIn: endOfDay.unix()
                                    });
                                } else {
                                    this.updateFilters({
                                        expireIn: undefined
                                    });
                                }
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                        mx: {
                                            xs: 0,
                                            sm: 2
                                        }
                                    }}
                                />
                            )}
                        />

                    </LocalizationProvider>
                    <SelectInput
                        options={this.state.structures}
                        label="Structures"
                        filter={(structure_id: number) => {
                            this.updateFilters({
                                structureId: isNaN(structure_id) ? undefined : structure_id
                            })
                        }}
                        structureId={this.state.filters.structureId || ''}
                    />
                    <FormGroup sx={{color: 'text.primary',px: {
                            xs: 0,
                            sm: 4
                        }}}>
                        {[
                            {
                                name: 'Administrateurs',
                                action: (_event: any) => {
                                    const newValue = !this.state.isAdmin;
                                    this.setState({
                                        isAdmin: newValue,
                                        filters: {
                                            ...this.state.filters,
                                            isAdmin: newValue ? newValue : undefined
                                        }
                                    }, () => this.load())
                                },
                            },
                            {
                                name: 'CNPF',
                                action: (_event: any) => {
                                    const newValue = !this.state.isCnpf;
                                    this.setState({
                                        isCnpf: newValue,
                                        filters: {
                                            ...this.state.filters,
                                            isCnpf: newValue ? newValue : undefined
                                        }
                                    }, () => this.load())
                                },
                            },
                            {
                                name: 'Inactif',
                                action: (_event: any) => {
                                    const newValue = !this.state.isActive;
                                    this.setState({
                                        isActive: newValue,
                                        filters: {
                                            ...this.state.filters,
                                            isActive: !newValue ? newValue : undefined
                                        }
                                    }, () => this.load())
                                },
                            }
                        ].map((object, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        onChange={object.action}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: 'secondary.main',
                                            },
                                        }}
                                    />
                                }
                                label={object.name}
                            />
                        ))}
                    </FormGroup>
                </Box>{' '}
                {this.state.is_loaded ? (
                    <UserTable
                        users={this.state.users}
                        reload={async () => {
                            await this.load()
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            width: 1,
                            height: '500px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress/>
                    </Box>
                )}
                {this.state.users && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        mt: 2
                    }}>
                        <Pagination
                            count={this.state.pagination?.totalPages || 1}
                            page={this.state.pagination?.page || 1}
                            onChange={(event, page) => this.handlePageChange(page)}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Box>
                )}

            </Box>
        )
    }
}
