import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import {Project} from '../../interfaces/project_interface'
import {ProjectRow} from './project_row'
import React from 'react'
import {ProjectApi} from '../../api/projects_api'

export interface ProjectsList {
    projects: Project[]
}

export class UserProjects extends React.Component<ProjectsList> {
    async downloadAll() {
        try {
            const download = await ProjectApi.usersProjectsCsv()
            const blobUrl = URL.createObjectURL(download)
            const link = document.createElement('a')

            link.href = blobUrl
            link.download = `projects.zip`
            link.click()
            URL.revokeObjectURL(blobUrl)
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        return (
            <Box sx={{p: 2}}>
                <Box sx={{mb: 2}}>
                    <h2>Liste des projets de l'utilisateur</h2>
                </Box>
                <Button sx={{mb: 2}} variant="outlined" onClick={this.downloadAll}>
                    Télécharger tout mes projets
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead
                            sx={{
                                backdropFilter: 2,
                                backgroundColor: 'primary.main',
                            }}
                        >
                            <TableRow>
                            <TableCell sx={{color: '#ffffff'}}>
                                Nom du projet</TableCell>
                            <TableCell sx={{color: '#ffffff'}}>
                                Opérateur</TableCell>
                            <TableCell sx={{color: '#ffffff'}}>
                                Télécharger</TableCell>
                            <TableCell sx={{color: '#ffffff'}}>
                                Télécharger</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.projects.map((project) => (
                                <ProjectRow project={project}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }
}
