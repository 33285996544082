import {Box, Button, Modal, Paper, TextField} from '@mui/material'
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, {Dayjs} from 'dayjs'
import React from 'react'
// TODO : supprimer le dossier parent de ce fichier

export interface RenewLicenceProps {
    onClose: () => void
    onSubmit: (newDate: number) => void
}

export interface RenewLicenceState {
    date: Dayjs,
}

export class RenewLicence extends React.Component<
    RenewLicenceProps,
    RenewLicenceState
> {
    constructor(props: RenewLicenceProps) {
        super(props)
        this.state = {
            date: dayjs(),
        }
        this.changeDate = this.changeDate.bind(this)
    }

    changeDate(newDate: Dayjs | null
    ) {
        if (newDate !== null) {
            this.setState({date: newDate})
        }
    }

    render() {
        return (
            <Modal open={true}>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backdropFilter: 'blur( 5px )',
                        WebkitBackdropFilter: 'blur( 5px )',
                    }}
                    display="flex"
                    position="fixed"
                >
                    <Box
                        component={Paper}
                        sx={{
                            p: 3,
                            border: 1,
                            borderColor: 'primary.light',
                            maxWidth: 0.2,
                        }}
                        id="info-popup"
                    >
                        <Box sx={{mb: 3}}>
                            <h2>Choisir une date</h2>
                            <p>
                                Veuillez définir une nouvelle date d'expiration
                                pour les comptes sélectionnés
                            </p>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="date"
                                inputFormat="DD/MM/YYYY"
                                value={this.state.date}
                                onChange={this.changeDate}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{py: 2}}
                                        variant="standard"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Box>
                            <Button
                                sx={{marginRight: 2}}
                                variant="contained"
                                id="confirm-button"
                                onClick={() => {
                                this.props.onSubmit(this.state.date.unix())
                                this.props.onClose()
                            }}
                                >
                                Continuer
                        </Button>

                            <Button
                                color="inherit"
                                variant="outlined"
                                onClick={async () => {
                                    this.props.onClose()
                                }}
                                id="close-modal"
                                type="button"
                            >
                                Annuler
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}
