import React, { useEffect, useState } from 'react';
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Icon from '@mdi/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    mdiAccount,
    mdiAccountSearch,
    mdiAlphaCCircle,
    mdiCodeJson,
    mdiExport,
    mdiForest,
    mdiHandshake,
    mdiHome,
    mdiLeaf,
    mdiMap,
    mdiScaleBalance,
    mdiSelectionMarker,
    mdiUpdate,
    mdiLockOff,
    mdiCellphone
} from '@mdi/js';


interface User {
    lastname: string;
    firstname: string;
    isAdmin: boolean;
}

interface MiniDrawerProps {
    current_user: User;
}

interface NavigationLink {
    name?: string;
    uri?: string;
    icon?: string;
    section?: string;
}

const drawerWidth = 240;

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const SectionHeader: React.FC<{ section: string; open: boolean }> = ({ section, open }) => (
    <Box sx={{ mt: 2, mb: 0.5 }}>
        {open && (
            <Typography variant="overline" sx={{ pl: 2 }} color="info.main">
                {section}
            </Typography>
        )}
    </Box>
);

const NavigationItem: React.FC<{ item: NavigationLink; isActive: boolean; open: boolean; handleNavigation: (uri: string | undefined) => void }> = ({
                                                                                                                                                       item,
                                                                                                                                                       isActive,
                                                                                                                                                       open,
                                                                                                                                                       handleNavigation,
                                                                                                                                                   }) => {
    if (!item.icon) return null;
    return (
        <ListItem key={item.uri} disablePadding>
            <ListItemButton
                onClick={(event) => {
                    if (event.ctrlKey || event.metaKey) {
                        window.open(item.uri, '_blank', 'noopener,noreferrer');
                    } else {
                        handleNavigation(item.uri);
                    }
                }}
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    backgroundColor: isActive ? 'primary.light' : 'transparent',
                    '& .MuiListItemText-primary': {
                        fontWeight: isActive ? 1000 : 300,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        transition: theme => theme.transitions.create(['transform', 'font-weight'], {
                            duration: theme.transitions.duration.shorter
                        })
                    },
                    '& .MuiListItemIcon-root': {
                        color: 'background.default',
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                        '& .MuiSvgIcon-root': {
                            transition: theme => theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shorter
                            })
                        }
                    },
                    '&:hover': {
                        backgroundColor: isActive ? 'primary.light' : 'action.hover',
                        '& .MuiListItemText-primary': {
                            fontWeight: 1000,
                        },
                    }
                }}
            >
                <ListItemIcon>
                    <Icon path={item.icon} size={1} />
                </ListItemIcon>
                {open && <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />}
            </ListItemButton>
        </ListItem>
    );
};


const SideNav: React.FC<MiniDrawerProps> = ({ current_user }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(!isMobile);
    const navigate = useNavigate();
    const location = useLocation();

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleNavigation = (uri: string | undefined) => {
        if (uri) {
            navigate(uri);
        }
    };

    const isCurrentPath = (uri: string | undefined) => {
        return location.pathname === uri;
    };

    const navigationLinks: NavigationLink[] = [
        { name: 'Accueil', uri: '/accueil', icon: mdiHome },
        { section: 'Export' },
        { name: 'Export', uri: '/export', icon: mdiExport },
        { section: 'Informations' },
        { name: 'Mon Profil', uri: '/monprofil', icon: mdiAccount },
        { name: 'Mes Projets', uri: '/mesprojets', icon: mdiForest },
        { name: 'APK', uri: '/apk', icon: mdiCellphone }
    ];

    const adminLinks: NavigationLink[] = [
        { name: 'Structures', uri: '/structures', icon: mdiLeaf },
        { name: 'Georestrictions', uri: '/georestrictions', icon: mdiSelectionMarker },
        { name: 'Utilisateurs', uri: '/utilisateurs', icon: mdiAccountSearch },
        { name: 'Algos JS', uri: '/algos_js', icon: mdiCodeJson },
        { name: 'Cartes Rasters', uri: '/rasters', icon: mdiMap },
        { name: 'Credits', uri: '/credits', icon: mdiAlphaCCircle },
        { name: 'Financeurs', uri: '/financeurs', icon: mdiHandshake },
        { name: 'Versions Algos', uri: '/versions_algos', icon: mdiUpdate },
        { name: 'CGU', uri: '/cgu', icon: mdiScaleBalance },
    ];

    const disconnect = () => {
        localStorage.removeItem('userToken');
        window.location.pathname = '/';
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: open ? drawerWidth : 65,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: open ? drawerWidth : 65,
                    overflowX: 'hidden',
                    backgroundColor: 'primary.main',
                    borderRight: 'none',
                    color: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiIconButton-root': {
                        color: 'background.default',
                    },
                    transition: theme => theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            }}
        >

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                <IconButton onClick={handleDrawerToggle}>
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Box>

            <List>
                {navigationLinks.map((item) => {
                    if (item.section) {
                        return <SectionHeader key={item.section} section={item.section} open={open} />;
                    }
                    const isActive = isCurrentPath(item.uri);
                    return <NavigationItem key={item.uri} item={item} isActive={isActive} open={open} handleNavigation={handleNavigation} />;
                })}

                {current_user.isAdmin && (
                    <>
                        <SectionHeader section="Administration" open={open} />
                        {adminLinks.map((item) => {
                            const isActive = isCurrentPath(item.uri);
                            return <NavigationItem key={item.uri} item={item} isActive={isActive} open={open} handleNavigation={handleNavigation} />;
                        })}
                    </>
                )}

                <SectionHeader section="Paramètres" open={open} />

                <ListItem disablePadding>
                    <ListItemButton
                        onClick={disconnect}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            color: 'background.default',
                            '& .MuiListItemIcon-root': {
                                color: 'background.default',
                                minWidth: 0,
                                mr: open ? 2 : 'auto',
                                justifyContent: 'center',
                            },
                            '&:hover': {
                                backgroundColor: 'error.light',
                                color: 'white',
                                '& .MuiListItemIcon-root': {
                                    color: 'white',
                                },
                            },
                        }}
                    >
                        <ListItemIcon>
                            <Icon path={mdiLockOff} size={1} />
                        </ListItemIcon>
                        {open && <ListItemText primary="Déconnexion" />}
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default SideNav;
