
export const SeverityEnum= {
    ZERO: 'Non estimée',
    ONE: 'Sévérité nulle',
    TWO: 'Faible sévérité',
    THREE: 'Sévérité moyenne',
    FOUR: 'Problème sévère',
    FIVE: 'Problème très sévère',
}

export const SeverityEnumMapping:{ [key: string]: string }  = {
    '0': SeverityEnum.ZERO,
    '1': SeverityEnum.ONE,
    '2': SeverityEnum.TWO,
    '3': SeverityEnum.THREE,
    '4': SeverityEnum.FOUR,
    '5': SeverityEnum.FIVE,
};
