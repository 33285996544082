import { mdiDelete, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, TableCell, TableRow, TextField, Tooltip } from '@mui/material'
import { useState } from 'react'

// TODO : déplacer dans structures_route
// TODO : ajouter un typage fort aux arguments
// TODO : supprimer le export default
const StructureRow = ({ structure, askForSave, askForDelete }: any) => {
    const [structureName, setName] = useState(structure.name)
    const [isEditingName, setEditing] = useState(false)

    // TODO : Cette fonction est-elle vraiment utile ?
    const editStructureName = (name: string) => {
        setName(name)
    }

    const toggleEditing = () => {
        setEditing(!isEditingName)
    }

    return (
        <TableRow
            sx={{
                '&:hover': {
                    backgroundColor: 'background.default',
                },
            }}
        >
            <TableCell>{structure.name}</TableCell>
            <TableCell>
                {isEditingName ? (
                    <>
                        <TextField
                            variant="standard"
                            onChange={(event) => {
                                editStructureName(event.target.value)
                            }}
                            value={structureName}
                        />
                        <Button
                            sx={{ marginLeft: 1 }}
                            onClick={() => {
                                askForSave(structure.id, structureName)
                                toggleEditing()
                            }}
                        >
                            Enregistrer
                        </Button>
                    </>
                ) : (
                    <Tooltip title="Éditer" placement="right">
                        <Button variant="outlined" onClick={toggleEditing}>
                            <Icon path={mdiPencil} />
                        </Button>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell>
                <Tooltip title="Détruire" placement="right">
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                            askForDelete(structure)
                        }}
                    >
                        <Icon path={mdiDelete} />
                    </Button>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default StructureRow
