import { Text, View } from '@react-pdf/renderer';
import React from "react";
import { stylesTable } from "./StylesTable";
import { headers } from "./INBHelpers";

interface BasePDFTableProps<T extends { essence: string }> {
    data: T[];
    renderCell: (item: T, columnIndex: number) => React.ReactNode;
}

const BasePDFTable = <T extends { essence: string }>({ data, renderCell }: BasePDFTableProps<T>) => {
    return (
        <View style={stylesTable.table}>
            <View style={stylesTable.tableRow}>
                {headers.map((header) => (
                    <View key={header} style={stylesTable.headerCell}>
                        <Text>{header}</Text>
                    </View>
                ))}
            </View>

            {data.map((item) => (
                <View key={item.essence} style={stylesTable.tableRow} wrap={false}>
                    {[0, 1, 2, 3].map((columnIndex) => (
                        <View
                            key={columnIndex}
                            style={columnIndex === 0 ? stylesTable.cellEssence : stylesTable.cell}
                        >
                            {renderCell(item, columnIndex)}
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
};

export default BasePDFTable;