import { mdiDelete, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, TableCell, TableRow, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { GeoRestriction } from '../../interfaces/georestriction_interface'

function GeorestrictionRow(props: {
    georestriction: GeoRestriction
    askForDelete: (georestriction: GeoRestriction) => void
}) {
    return (
        <TableRow
            sx={{
                '&:hover': {
                    backgroundColor: 'background.default',
                },
            }}
        >
            <TableCell>{props.georestriction.name}</TableCell>
            <TableCell>
                <Link to={`edit/${props.georestriction.id}`}>
                    <Tooltip title="Éditer" placement="right">
                        <Button variant="outlined">
                            <Icon path={mdiPencil} />
                        </Button>
                    </Tooltip>
                </Link>
            </TableCell>
            <TableCell>
                <Tooltip title="Supprimer" placement="right">
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                            props.askForDelete(props.georestriction)
                        }}
                    >
                        <Icon path={mdiDelete} />
                    </Button>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default GeorestrictionRow
