import {ProjetDiebackTree} from "../../../../interfaces/project_interface";

type ARCHIState = 'Sain' | 'Résilient' | 'Descente de cime' | 'Stressé' | 'Dépérissement irréversible' | 'Mort';
type DEPERISState = 'A' | 'B' | 'C' | 'D' | 'E' | 'F';

export interface DataItem {
    label: string;
    percentage: number;
    count: number;
    color: string;
}

export const LIST_COLOR_ARCHI = {
    'Sain': { color: '#385723' },
    'Résilient': { color: '#00B050' },
    'Descente de cime': { color: '#A9D18E' },
    'Stressé': { color: '#FAFF25' },
    'Dépérissement irréversible': { color: '#FF9201' },
    'Mort': { color: '#C31313' },
} as const;


export const LIST_COLOR_DEPERIS = {
    'A': { color: '#385723' },
    'B': { color: '#00B050' },
    'C': { color: '#A9D18E' },
    'D': { color: '#FAFF25' },
    'E': { color: '#FF9201' },
    'F': { color: '#C31313' },
} as const;


export const calculateArchiStatistics = (trees: ProjetDiebackTree[]): DataItem[] => {
    const stateCounts = trees.reduce((acc, tree) => {
        const state = tree.protocolARCHI as ARCHIState;
        if (state) {
            acc[state] = (acc[state] || 0) + 1;
        }
        return acc;
    }, {} as Record<string, number>);

    const totalTrees = Object.values(stateCounts).reduce((sum, count) => sum + count, 0);

    return Object.entries(LIST_COLOR_ARCHI)
        .map(([state, config]) => ({
            label: state,
            count: stateCounts[state] || 0,
            percentage: totalTrees ? Math.round((stateCounts[state] || 0) * 100 / totalTrees) : 0,
            color: config.color
        }))
        .filter(item => item.count > 0);
};

export const calculateDeperisStatistics = (trees: ProjetDiebackTree[]): DataItem[] => {
    const noteCounts = trees.reduce((acc, tree) => {
        const note = tree.protocolDEPERISNote as DEPERISState;
        if (note) {
            acc[note] = (acc[note] || 0) + 1;
        }
        return acc;
    }, {} as Record<string, number>);

    const totalTrees = Object.values(noteCounts).reduce((sum, count) => sum + count, 0);

    return Object.entries(LIST_COLOR_DEPERIS)
        .map(([note, config]) => ({
            label: note,
            count: noteCounts[note] || 0,
            percentage: totalTrees ? Math.round((noteCounts[note] || 0) * 100 / totalTrees) : 0,
            color: config.color
        }))
        .filter(item => item.count > 0);
};