import React from "react";
import {useParams} from "react-router-dom";
import {
    ListeEssences,
    ProblemeSanitaire,
    Project
} from "../../interfaces/project_interface";
import {ProjectApi} from "../../api/projects_api";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ReactComponent as IconDeperis} from "../../assets/images/ic_deperissement.svg";
import {ReactComponent as IconGeneralInfo} from "../../assets/images/ic_renseignements_projet.svg";
import {ReactComponent as IconSanitary} from "../../assets/images/ic_observations_sanitaires.svg";
import {ReactComponent as IconPedology} from "../../assets/images/ic_description_pedologique.svg";
import {ReactComponent as IconSylvicole} from "../../assets/images/ic_description_sylvicole.svg";
import {ReactComponent as IconTopographic} from "../../assets/images/ic_contexte_topographique.svg";
import {ReactComponent as IconClimatic} from "../../assets/images/ic_donnees_climatiques.svg";
import {SlopeUnitMapping} from "../../enums/SlopeUnit";
import {ConfinementUnitMapping} from "../../enums/ConfinementUnit";
import {TopographicPositionMapping} from "../../enums/TopographicPosition";
import {WaterSupplyMapping} from "../../enums/WaterSupply";
import {ReasonStopAugerMapping} from "../../enums/ReasonStopAuger";
import {CompactnessMapping} from "../../enums/CompactnessValues";
import {HumusMapping} from "../../enums/Humus";
import {PropDeperisTreeMapping} from "../../enums/PropDeperisTree";
import {ResilienceMapping} from "../../enums/Resilience";
import {MajorIssueMapping} from "../../enums/MajorIssue";
import {SeverityEnumMapping} from "../../enums/Severity";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getSeverityPercentageLabel} from "../../utils/sanitaryLabels";
import {ClimateData} from "../../interfaces/climateData_interface";
import {TextureEnum, TextureKey} from "../../enums/Texture";
import DeperissementDiagram from "./components/DeperissementDiagram";
import AppHeader from "../../components/common/AppHeader";


export function ViewProjectMiddleWare(): React.ReactElement {
    return <ViewProject params={useParams()}/>
}

export interface ViewProjectProps {
    params?: any
}

interface ViewProjectState {
    project: Project;
    climateData: ClimateData | null;
}

export class ViewProject extends React.Component<ViewProjectProps, ViewProjectState> {
    constructor(props: ViewProjectProps) {
        super(props)
        this.state = {
            project: {
                id: -1,
                userId: -1,
                data: {
                    name: '',
                    lat: 0,
                    lon: 0,
                    commune: '',
                    comment: '',
                    date: '',
                    creationDate: '',
                    operator: '',
                    forestName: '',
                    pedology: {
                        causeArretTariere: '',
                        comment: '',
                        compactness: '',
                        groundType: '',
                        horizons: [],
                        humus: '',
                        ph20: 0,
                        phMeasured: false,
                        rockType: '',
                        ucs: 0,
                        usefulReserve: 0,
                        usefulReserve200: 0
                    },
                    dieback: {
                        comment: '',
                        environmentFrequency: '',
                        environmentIntensity: '',
                        hasMandatoryComplete: '',
                        parcelFrequency: '',
                        parcelIntensity: '',
                        tree: []
                    },
                    populationCharacteristics: {
                        woodSpecies: '',
                        populationType: '',
                        recentInterventions: '',
                        averageDiameter: '',
                        averageDiameterMeasured: false,
                        dominantHeight: 0,
                        dominantHeightMeasured: false,
                        basalArea: 0,
                        basalAreaMesured: false,
                        age: 0,
                        ageMeasured: 0,
                        comment: ''
                    },
                    sanitaryIssues: {
                        guiSeverity: '',
                        guiAffectedStems: '',
                        defoliateurSeverity: '',
                        defoliateurAffectedStems: '',
                        scolyteSeverity: '',
                        scolyteAffectedStems: '',
                        cynipsSeverity: '',
                        cynipsAffectedStems: '',
                        otherInsectsSeverity: '',
                        otherInsectsAffectedStems: '',
                        chancreSeverity: '',
                        chancreAffectedStems: '',
                        encreSeverity: '',
                        encreAffectedStems: '',
                        oidiumSeverity: '',
                        oidiumAffectedStems: '',
                        otherFungiSeverity: '',
                        otherFungiAffectedStems: '',
                        decolorationSeverity: '',
                        decolorationAffectedStems: '',
                        necrosesSeverity: '',
                        necrosesAffectedStems: '',
                        comment: ''
                    },
                    stationaryContext: {
                        altitude: 0,
                        exposureUnit: '',
                        exposureValue: 0,
                        slopeUnit: '',
                        slopeValue: 0,
                        containmentUnit: '',
                        containmentValue: 0,
                        topographicPosition: '',
                        tpi: 0,
                        watterSupply: '',
                        rockyOutcrops: 0,
                        terracements: false,
                        comment: ''
                    }
                },
                timeCreate: 0,
                timeUpdate: 0,
                archive: false,
                shareWithCnpf: false,
                isClosed: false
            },
            climateData: null
        }
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            if (this.props.params) {
                const params = this.props.params['*'].split('/')
                const id = params[1]
                try {
                    const project = await ProjectApi.getProject(id);
                    this.setState({
                        project: {
                            id: project.id,
                            userId: project.userId,
                            data: project.data,
                            timeCreate: project.timeCreate,
                            timeUpdate: project.timeUpdate,
                            archive: project.archive,
                            shareWithCnpf: project.shareWithCnpf,
                            isClosed: project.isClosed
                        }
                    })
                } catch (projectError) {
                    console.error("Erreur lors du chargement du projet:", projectError)
                    alert("Échec du chargement des données du projet")
                    return
                }

                try {
                    const climateData = await ProjectApi.getClimateData(id);
                    if (climateData) {
                        this.setState({climateData});
                    }
                } catch (climateError) {
                    console.error("Erreur données climatiques:", climateError);
                }
            }
        } catch (e) {
            console.error("Erreur générale:", e)
            alert("Une erreur est survenue lors du chargement des données")
        }
    }


    render() {
        return (
            <Box sx={{p: 5}}>
                <AppHeader title={`Détails du projet ${this.state.project.data.name}`} />
                <Grid container spacing={6} justifyContent="center">
                    <Grid item xs={12} sm={6} md={5} style={{paddingTop: '60px'}}>
                        <Box sx={{
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            overflow: 'hidden',
                            position: 'relative',
                            mb: 5,
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <IconGeneralInfo
                                        style={{
                                            width: 50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Renseignements projet
                                    </Typography>
                                </Box>
                            </Box>
                            {this.state.project.data.operator && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Opérateur:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>{this.state.project.data.operator.replace(/^./, this.state.project.data.operator[0].toUpperCase())}</Typography>
                                </Box>
                            )}
                            {this.state.project.data.forestName && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Forêt:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>{this.state.project.data.forestName.replace(/^./, this.state.project.data.forestName[0].toUpperCase())}</Typography>
                                </Box>
                            )}
                            {this.state.project.data.commune && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Commune:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>{this.state.project.data.commune.replace(/^./, this.state.project.data.commune[0].toUpperCase())}</Typography>
                                </Box>
                            )}
                            {!!(this.state.project.timeCreate) && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Date de création:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {new Date(this.state.project.timeCreate * 1000).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            )}
                            {this.state.project.timeUpdate && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Date de mise à jour:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}> {new Date(this.state.project.timeUpdate * 1000).toLocaleDateString()}</Typography>
                                </Box>
                            )}
                            {this.state.project.data.lat && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Latitude:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>{this.state.project.data.lat}</Typography>
                                </Box>
                            )}
                            {this.state.project.data.lon && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Longitude:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>{this.state.project.data.lon}</Typography>
                                </Box>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    État du projet:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.isClosed ? 'Fermé' : 'Ouvert'}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Archivage:</Typography>
                                <Typography>{this.state.project.archive ? 'Archivé' : 'Non archivé'}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Projet partagé avec le CNPF:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.shareWithCnpf ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            {this.state.project.data.comment && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Commentaire:
                                    </Typography>
                                    <Typography>{this.state.project.data.comment}</Typography>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1,
                            mb: 5,
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <IconSylvicole
                                        style={{
                                            width: 50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Description sylvicole
                                    </Typography>
                                </Box>
                            </Box>
                            {this.state.project.data.populationCharacteristics.woodSpecies !== null && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Essence:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {
                                            this.state.project.data.populationCharacteristics?.woodSpecies
                                                ?.replace(/_/g, ' ')
                                                ?.replace(/^./, char => char.toUpperCase()) ?? ''
                                        }
                                    </Typography>
                                </Box>

                            )}
                            {this.state.project.data.populationCharacteristics.populationType !== null && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Type de peuplement:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {
                                            this.state.project.data.populationCharacteristics?.populationType
                                                ?.replace(/_/g, ' ')
                                                ?.replace(/^./, char => char.toUpperCase()) ?? ''
                                        }
                                    </Typography>
                                </Box>
                            )}
                            {this.state.project.data.populationCharacteristics.recentInterventions !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Intervention récente:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {
                                            this.state.project.data.populationCharacteristics?.recentInterventions
                                                ?.replace(/_/g, ' ')
                                                ?.replace(/^./, char => char.toUpperCase()) ?? ''
                                        }
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.averageDiameter !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Diamètre moyen:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.populationCharacteristics.averageDiameter} cm
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Diamètre moyen mesurée:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.populationCharacteristics.averageDiameterMeasured ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            {this.state.project.data.populationCharacteristics.dominantHeight !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Hauteur dominante:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.populationCharacteristics.dominantHeight} m
                                    </Typography>
                                </Box>

                            ) : (
                                <></>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Hauteur dominante mesurée:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.populationCharacteristics.dominantHeightMeasured ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            {this.state.project.data.populationCharacteristics.basalArea !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Surface Terrière:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.populationCharacteristics.basalArea} m²/ha
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Surface Terrière mesurée:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.populationCharacteristics.basalAreaMesured ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            {this.state.project.data.populationCharacteristics.age !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Age:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.populationCharacteristics.age} ans
                                    </Typography>
                                </Box>

                            ) : (
                                <></>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Age connu:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.populationCharacteristics.ageMeasured ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            <Accordion sx={{mt: 2}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="sanitary-issues-content"
                                    id="sanitary-issues-header"
                                >
                                    <Typography fontWeight="bold" sx={{fontSize: '0.9rem'}}>
                                        Liste des essences présentes
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.project.data.populationCharacteristics?.listeEssences ? (

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead sx={{backdropFilter: 2, backgroundColor: 'secondary.main'}}>
                                                    <TableRow>
                                                        <TableCell sx={{color: "#ffffff"}}>Essence</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Diamètre moyen
                                                            (cm)</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Hauteur dominante
                                                            (m)</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>Age (ans)</TableCell>
                                                        <TableCell sx={{color: "#ffffff"}}>G (m²/ha)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.populationCharacteristics?.listeEssences.map((essence: ListeEssences) => (
                                                        <TableRow
                                                            key={essence.id}
                                                        >
                                                            <TableCell sx={{py: 2}}>
                                                                {essence.essenceLabel}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.diametreMoyen}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.hauteurDominante}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.ageMoyen}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {essence.surfaceTerriere}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <Typography
                                            sx={{fontSize: '0.9rem'}}>Aucune essence enregistrée</Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box sx={{
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <IconClimatic
                                        style={{
                                            width: 50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />

                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Données climatiques
                                    </Typography>
                                </Box>
                            </Box>
                            <Accordion sx={{mt: 2}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="sanitary-issues-content"
                                    id="sanitary-issues-header"
                                >
                                    <Typography fontWeight="bold" sx={{fontSize: '0.9rem'}}>
                                        Détails des données climatiques
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{
                                        textAlign: 'center',
                                        backgroundColor: 'rgba(182, 193, 151, 0.2)',
                                        p: 2,
                                    }}>
                                        <Typography variant="h4" sx={{fontSize: '1.1rem', fontWeight: 'bold', m: 2}}>
                                            Valeurs Moyennes sur la période 1981-2010
                                        </Typography>
                                        <Typography sx={{fontSize: '0.9rem'}}>
                                            Issues du modèle NorCliS@CNPF construit à partir des
                                            données Météo-France et AgroParisTech
                                        </Typography>
                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                backgroundColor: 'white',
                                                p: 2,
                                                m: 2
                                            }}
                                        >
                                            <Typography variant="h4" sx={{
                                                fontSize: '1.1rem',
                                                fontWeight: 'bold',
                                                mb: 1
                                            }}>Températures</Typography>
                                            <Divider></Divider>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>Moyenne des
                                                    annuelles (TMAN)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.TMAN ? `${this.state.climateData.TMAN} °C` : 'Non disponible'}</Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>Moyenne des
                                                    maximales juin-août (TX0608)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.TX0608 ? `${this.state.climateData.TX0608} °C` : 'Non disponible'}</Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>Moyenne des
                                                    minimales de janvier (TN01)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.TN01 ? `${this.state.climateData.TN01} °C` : 'Non disponible'}</Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>Température
                                                    minimale absolue (TNABS)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.TNABS ? `${this.state.climateData.TNABS} °C` : 'Non disponible'}</Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                backgroundColor: 'white',
                                                p: 2,
                                                m: 2
                                            }}
                                        >
                                            <Typography variant="h4" sx={{
                                                fontSize: '1.1rem',
                                                fontWeight: 'bold',
                                                mb: 1
                                            }}>Précipitations</Typography>
                                            <Divider></Divider>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>Précipitations
                                                    annuelles (Pan)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.Pan ? `${this.state.climateData.Pan} mm` : 'Non disponible'}</Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>Précipitations
                                                    avril-octobre (P0410)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.P0410 ? `${this.state.climateData.P0410} mm` : 'Non disponible'}</Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                backgroundColor: 'white',
                                                p: 2,
                                                m: 2
                                            }}
                                        >
                                            <Typography variant="h4"
                                                        sx={{fontSize: '1.1rem', fontWeight: 'bold', mb: 1}}>ETP et
                                                bilans hydriques climatiques</Typography>
                                            <Divider></Divider>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>ETP (Turc)
                                                    annuelle (ETPan)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.ETPan ? `${this.state.climateData.ETPan} mm` : 'Non disponible'}</Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>P-ETP (Turc)
                                                    juin-août (PETP0608)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.PETP0608 ? `${this.state.climateData.PETP0608} mm` : 'Non disponible'}</Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                m: 1
                                            }}>
                                                <Typography sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>P-ETP (Turc)
                                                    mai-septembre (PETP0509)</Typography>
                                                <Typography
                                                    sx={{fontSize: '0.9rem'}}> {this.state.climateData?.PETP0509 ? `${this.state.climateData.PETP0509} mm` : 'Non disponible'}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} style={{paddingTop: '60px'}}>
                        <Box sx={{
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1,
                            mb: 5,
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>

                                    <IconTopographic
                                        style={{
                                            width:50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />

                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Contexte topographique
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Altitude:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.stationaryContext.altitude} m</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Exposition:
                                </Typography>
                                <Typography sx={{fontSize: '0.9rem'}}>
                                    {this.state.project.data.stationaryContext.exposureValue && this.state.project.data.stationaryContext.exposureUnit
                                        ? `${this.state.project.data.stationaryContext.exposureValue} ${this.state.project.data.stationaryContext.exposureUnit}`
                                        : 'Aucune'}
                                </Typography>
                            </Box>
                            {this.state.project.data.stationaryContext.slopeValue !== null && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Pente:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.stationaryContext.slopeValue}
                                        {SlopeUnitMapping[this.state.project.data.stationaryContext.slopeUnit as string]}
                                    </Typography>
                                </Box>

                            )}
                            {this.state.project.data.stationaryContext.containmentValue !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Confinement:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.stationaryContext.containmentValue}
                                        {ConfinementUnitMapping[this.state.project.data.stationaryContext.containmentUnit as string]}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.topographicPosition !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Position topographique:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>
                                        {TopographicPositionMapping[this.state.project.data.stationaryContext.topographicPosition as string]}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.tpi !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        TPI:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.stationaryContext.tpi}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.watterSupply !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Alimentation en eau:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>
                                        {WaterSupplyMapping[this.state.project.data.stationaryContext.watterSupply as string]}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.rockyOutcrops !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Affleurements rocheux:
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: '0.9rem'}}>{this.state.project.data.stationaryContext.rockyOutcrops} %</Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Présence de terrasse:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.stationaryContext.terracements ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            {this.state.project.data.stationaryContext.comment && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Commentaire :
                                    </Typography>
                                    <Typography>{this.state.project.data.stationaryContext.comment}</Typography>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1,
                            mb: 5,
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <IconPedology
                                        style={{
                                            width: 50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Description du sol
                                    </Typography>
                                </Box>
                            </Box>
                            {this.state.project.data.pedology.causeArretTariere !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Cause arrêt tarière:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {ReasonStopAugerMapping[this.state.project.data.pedology.causeArretTariere as string]}
                                    </Typography>
                                </Box>

                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.compactness !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Compacité:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {CompactnessMapping[this.state.project.data.pedology.compactness as string]}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.humus !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Humus:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {HumusMapping[this.state.project.data.pedology.humus as string]}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.ph20 !== null ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        PH à 20 cm:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.pedology.ph20}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    PH mesuré:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.pedology.phMeasured ? 'Oui' : 'Non'}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Réservoir utile en eau:
                                </Typography>
                                <Typography sx={{fontSize: '0.9rem'}}>Réservoir utile en eau
                                    : {this.state.project.data.pedology.usefulReserve != null && (
                                        `${this.state.project.data.pedology.usefulReserve.toFixed(2)} mm`
                                    )}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    UCS:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.pedology.ucs ? this.state.project.data.pedology.ucs : 'Non renseignée '}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Type de sol:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.pedology.groundType ? this.state.project.data.pedology.groundType : 'Non renseigné '}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Nature de la roche:
                                </Typography>
                                <Typography
                                    sx={{fontSize: '0.9rem'}}>{this.state.project.data.pedology.rockType ? this.state.project.data.pedology.rockType : 'Non renseignée '}</Typography>
                            </Box>
                            {this.state.project.data.pedology.comment && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Commentaire :
                                    </Typography>
                                    <Typography>{this.state.project.data.pedology.comment}</Typography>
                                </Box>
                            )}
                            {this.state.project.data.pedology.horizons.length ? (
                                <Accordion sx={{mt: 2}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="horizons-content"
                                        id="horizons-header"
                                    >
                                        <Typography fontWeight="bold" sx={{fontSize: '0.9rem'}}>
                                            Horizons
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper} sx={{marginTop: 1}}>
                                            <Table>
                                                <TableHead
                                                    sx={{
                                                        backdropFilter: 2,
                                                        backgroundColor: 'secondary.main',
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            #
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Épaisseur
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Texture
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            EG
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Calcaire actif
                                                        </TableCell>
                                                        <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                                            Hydromorphie
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.pedology.horizons
                                                        .map((horizons: any) => (
                                                            <TableRow
                                                                key={horizons.num}
                                                            >
                                                                <TableCell sx={{py: 2, textAlign: 'center'}}>
                                                                    {horizons.num}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {horizons.thickness}cm
                                                                </TableCell>
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {TextureEnum[horizons.texture as TextureKey]}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {horizons.eg}%
                                                                </TableCell>
                                                                {horizons.effervescence ? (
                                                                    <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                        OUI
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                        NON
                                                                    </TableCell>
                                                                )}
                                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                                    {horizons.hydromorphy}
                                                                </TableCell>
                                                            </TableRow>))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <></>
                            )}
                        </Box>

                        <Box sx={{
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <IconDeperis
                                        style={{
                                            width: 50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />

                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Dépérissement
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',

                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Proportion d'arbres dépérissants:
                                </Typography>
                                <Typography sx={{fontSize: '0.9rem'}}>
                                    {PropDeperisTreeMapping[this.state.project.data.dieback.parcelFrequency as string]}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Présence de résilience:
                                </Typography>
                                <Typography sx={{fontSize: '0.9rem'}}>
                                    {ResilienceMapping[this.state.project.data.dieback.parcelIntensity as string]}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Problème majeur récent (5 ans):
                                </Typography>
                                <Typography sx={{fontSize: '0.9rem'}}>
                                    {MajorIssueMapping[this.state.project.data.dieback.environmentFrequency as string]}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                m: 1,
                                pl: 2
                            }}>
                                <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                    Sévérité du problème:
                                </Typography>
                                <Typography sx={{fontSize: '0.9rem'}}>
                                    {SeverityEnumMapping[this.state.project.data.dieback.environmentIntensity as string]}
                                </Typography>
                            </Box>
                            {((this.state.project.data.dieback.comment !== "") && (this.state.project.data.dieback.comment !== null)) ? (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 2,
                                    m: 1,
                                    pl: 2
                                }}>
                                    <Typography fontWeight="bold" sx={{whiteSpace: 'nowrap', fontSize: '0.9rem'}}>
                                        Commentaire:
                                    </Typography>
                                    <Typography sx={{fontSize: '0.9rem'}}>
                                        {this.state.project.data.dieback.comment}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.dieback.tree?.length ? (
                                <Accordion sx={{mt: 2}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        sx={{backgroundColor: '#primary.light'}}
                                    >
                                        <Typography fontWeight="bold" sx={{fontSize: '0.9rem'}}>
                                            Notation des arbres
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead
                                                    sx={{
                                                        backdropFilter: 2,
                                                        backgroundColor: 'secondary.main',
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell sx={{color: '#ffffff'}}>#</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Ramif.</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Mort.</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Archi</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Deper.</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Essence</TableCell>
                                                        <TableCell sx={{color: '#ffffff'}}>Diam.</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.project.data.dieback.tree.map((arbres: any) => (
                                                        <TableRow key={arbres.treenumber}>
                                                            <TableCell sx={{py: 2}}>{arbres.treenumber}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolDEPRISLackRamifications}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolDEPERISBranchMortalities}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolARCHI}</TableCell>
                                                            <TableCell
                                                                sx={{py: 0}}>{arbres.protocolDEPERISNote}</TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {
                                                                    arbres.essence
                                                                        ?.replace(/_/g, ' ')
                                                                        ?.replace(/^./, (char: string) => char.toUpperCase())
                                                                    ?? ''
                                                                }
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>{arbres.diameter}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <DeperissementDiagram data={this.state.project.data.dieback.tree!}/>

                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={10}>
                        <Box sx={{
                            overflow: 'hidden',
                            position: 'relative',
                            borderLeft: '5px solid',
                            borderLeftColor: 'info.main',
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            borderRadius: 1
                        }}>
                            <Box sx={{
                                color: '#397831 ',
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <IconSanitary
                                        style={{
                                            width: 50,
                                            height: 'auto',
                                            filter: 'invert(22%) sepia(97%) saturate(503%) hue-rotate(93deg) brightness(97%) contrast(88%)'
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            margin: 0,
                                        }}
                                    >
                                        Observations sanitaires
                                    </Typography>
                                </Box>
                            </Box>
                            <Accordion sx={{mt: 2}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="sanitary-issues-content"
                                    id="sanitary-issues-header"
                                >
                                    <Typography fontWeight="bold" sx={{fontSize: '0.9rem'}}>
                                        Détails des observations sanitaires
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.project.data.sanitaryIssues?.listeProblemes && this.state.project.data.sanitaryIssues.listeProblemes.length > 0 ? (
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead
                                                        sx={{backdropFilter: 2, backgroundColor: 'secondary.main'}}>
                                                        <TableRow>
                                                            <TableCell sx={{color: "#ffffff"}}>Problème</TableCell>
                                                            <TableCell sx={{color: "#ffffff"}}>Sévérité</TableCell>
                                                            <TableCell sx={{color: "#ffffff"}}>% de tiges
                                                                atteintes</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.project.data.sanitaryIssues.listeProblemes
                                                            .map((problem: ProblemeSanitaire) => (
                                                                <TableRow
                                                                    key={problem.problemeId}
                                                                >
                                                                    <TableCell sx={{py: 2}}>
                                                                        {problem.problemeLabel}s
                                                                    </TableCell>
                                                                    <TableCell sx={{py: 0}}>
                                                                        {getSeverityPercentageLabel(problem.severite)}
                                                                    </TableCell>
                                                                    <TableCell sx={{py: 0}}>
                                                                        {problem.proportion}%
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>

                                    ) : (
                                        <Typography
                                            sx={{fontSize: '0.9rem'}}>
                                            Aucun problème sanitaire n'est
                                            enregistré
                                        </Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        )
    }
}