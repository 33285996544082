import React from 'react';
import {VigilenceColors} from '../../../assets/styles/colors';
import {Box, Typography} from "@mui/material";
import {DiagnosticPeuplement, DiagnosticTemperature} from "../../../interfaces/Diagnostic_interface";

interface PeuplementProps {
    diagnosticsPeuplement: DiagnosticPeuplement;
}

interface CircleProps {
    value: number;
    isDeperissement?: boolean;
    label: string;
}

interface TemperatureSectionProps {
    temperature: DiagnosticTemperature;
    label: string;
}

const getIndexColorDieback = (percent: number): number => {
    if (percent < 10) return 0;
    else if (percent < 20) return 1;
    else if (percent < 30) return 2;
    else if (percent < 40) return 3;
    else if (percent < 50) return 4;
    else if (percent < 60) return 5;
    else if (percent < 70) return 6;
    else if (percent < 80) return 7;
    else if (percent < 90) return 8;
    else if (percent < 95) return 9;
    else return 10;
};


const VigilenceCircle: React.FC<CircleProps> = ({value, isDeperissement = false, label}) => {
    const colorIndex = isDeperissement ? getIndexColorDieback(value) : value;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
        }}>
            <Box sx={{
                width: {xs: '80px', sm: '100px', md: '120px'},
                height: {xs: '80px', sm: '100px', md: '120px'},
                borderRadius: '50%',
                bgcolor: VigilenceColors.getVigilenceColor(colorIndex),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: {xs: '24px', sm: '26px', md: '30px'}
            }}>
                {isDeperissement ? `${value}%` : value}
            </Box>
            <Typography sx={{
                fontSize: {xs: '12px', sm: '13px', md: '14px'},
                textAlign: 'center'
            }}>
                {label}
            </Typography>
        </Box>
    );
};

const TemperatureSection: React.FC<TemperatureSectionProps> = ({temperature, label}) => {
    return (
        <Box>
            <Box sx={{
                backgroundColor: 'background.default',
            }}>
                <Typography sx={{
                    color: 'dark',
                    fontSize: '17px',
                    textAlign: 'center',
                    p: 1,
                    fontWeight: 500
                }}>
                    {label}
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                p: 2,
            }}>
                <VigilenceCircle
                    value={temperature.vigilence}
                    label="Vigilance"
                />
                <VigilenceCircle
                    value={temperature.deperissement}
                    isDeperissement={true}
                    label="Risque de dépérissement"
                />
            </Box>

            <Typography sx={{
                textAlign: 'center',
                fontSize: '15px',
                py: 1,
            }}>
                Fiabilité du modèle: {temperature.fiabilite}
            </Typography>
        </Box>
    );
};


const Peuplement: React.FC<PeuplementProps> = ({diagnosticsPeuplement}) => {
    const formatEssence = (essence: string): string => {
        return essence.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (

        <Box sx={{
            maxWidth: {
                xs: '100%',
                sm: '600px',
                md: '900px'
            },
            mx: 'auto',
            px: {
                xs: 2,
                sm: 3
            }
        }}>
            <Typography variant="h6" component="h2" gutterBottom
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
            >
                {formatEssence(diagnosticsPeuplement.essence)}
            </Typography>


            <Box sx={{
                border: '1px solid #e0e0e0',
            }}>
                <Box sx={{
                    backgroundColor: 'primary.main',
                }}>
                    <Typography sx={{
                        color: 'white',
                        fontSize: '22px',
                        py: 1,
                        textAlign: 'center',
                    }}>
                        Niveau de vigilance climat-sol
                    </Typography>
                </Box>

                <TemperatureSection
                    temperature={diagnosticsPeuplement.temperature[0]}
                    label="Température actuelle"
                />

                <TemperatureSection
                    temperature={diagnosticsPeuplement.temperature[1]}
                    label="Température +1°C"
                />

                <TemperatureSection
                    temperature={diagnosticsPeuplement.temperature[2]}
                    label="Température +2°C"
                />
            </Box>
        </Box>
    );
};

export default Peuplement;