import React, {useState, useCallback, useEffect, useRef} from 'react';
import { Box, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {ProjectApi, ProjectResponse} from "../../api/projects_api";

export interface FilterState {
    name: string;
    commune: string;
    creationDate: Date | null;
    updatedDate: Date | null;
}

export interface ProjectFilter {
    name?: string;
    commune?: string;
    creationDate?: string;
    updatedDate?: string;
    page?: number;
}

interface ProjectsFilterProps {
    onSearch: (response: ProjectResponse) => void;
}
export const ProjectsFilter: React.FC<ProjectsFilterProps> = ({ onSearch }) => {
    const [filters, setFilters] = useState<FilterState>({
        name: '',
        commune: '',
        creationDate: null,
        updatedDate: null
    });

    const handleSearch = useCallback(async (filters: any) => {
        try {
            const response = await ProjectApi.listUserProjects({
                name: filters.name,
                commune: filters.commune,
                creationDate: filters.creationDate?.unix(),
                updatedDate: filters.updatedDate?.unix(),
                page: 1
            });
            onSearch(response);
        } catch (error) {
            console.error('Erreur de recherche:', error);
            onSearch({
                data: [],
                pagination: {
                    page: 1,
                    limit: 25,
                    totalItems: 0,
                    totalPages: 0
                }
            });
        }
    }, [onSearch]);
    const debouncedSearch = useRef(
        debounce(handleSearch, 500)
    );

    useEffect(() => {
        const currentSearch = debouncedSearch.current;

        return () => {
            currentSearch.cancel();
        };
    }, []);

    const handleInputChange = (field: keyof FilterState) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const newFilters = { ...filters, [field]: newValue };
        setFilters(newFilters);
        debouncedSearch.current(newFilters);
    };

    const handleDateChange = (dateType: 'creationDate' | 'updatedDate', date: Date | null) => {
        const newFilters = { ...filters, [dateType]: date };
        setFilters(newFilters);
        debouncedSearch.current(newFilters);
    };


    return (
        <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 2, width: '25ch' } }}
            noValidate
            autoComplete="off"
        >
            <TextField
                label="Nom du projet"
                value={filters.name}
                onChange={handleInputChange('name')}
                variant="standard"
            />

            <TextField
                label="Commune"
                value={filters.commune}
                onChange={handleInputChange('commune')}
                variant="standard"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Date de création"
                    inputFormat="DD/MM/YYYY"
                    value={filters.creationDate}
                    onChange={(date) => handleDateChange('creationDate', date)}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                    )}
                />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Date de mise à jour"
                    inputFormat="DD/MM/YYYY"
                    value={filters.updatedDate}
                    onChange={(date) => handleDateChange('updatedDate', date)}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                    )}
                />
            </LocalizationProvider>
        </Box>
    );
};