export const ReasonStopAugerEnum = {
    SPINNER_TITLE: "Cause arrêt tarière",
    COARSE_ELEMENTS: "Éléments grossiers",
    SLAB: "Dalle",
    COMPACTNESS: "Compacité",
    HORIZON_TOO_DRY: "Horizon trop sec",
    VOLUNTARY: "Volontaire",
};

export const ReasonStopAugerMapping :{ [key: string]: string } = {
    'spinner_title': ReasonStopAugerEnum.SPINNER_TITLE,
    'coarse_elements': ReasonStopAugerEnum.COARSE_ELEMENTS,
    'slab': ReasonStopAugerEnum.SLAB,
    'comptness': ReasonStopAugerEnum.COMPACTNESS,
    'horizon_too_dry': ReasonStopAugerEnum.HORIZON_TOO_DRY,
    'voluntary': ReasonStopAugerEnum.VOLUNTARY,
};
