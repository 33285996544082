import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ProjetDiebackTree} from '../../../interfaces/project_interface';
import { calculateArchiStatistics, calculateDeperisStatistics, DataItem, LIST_COLOR_ARCHI, LIST_COLOR_DEPERIS} from "./common/DeperissementHelpers";


interface ChartProps {
    title: string;
    data: DataItem[];
}

interface DiagramProps {
    data: ProjetDiebackTree[];
}

const ChartContainer = styled(Box)(() => ({
    height: 250,
    display: 'flex',
    position: 'relative',
    backgroundColor: '#fff',
}));

const GridLine =styled(Box)(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    borderBottom: '1px dotted #CCCCCC',
}));


const BarChart: React.FC<ChartProps> = ({title, data}) => {
    const gridLines: number[] = Array.from({length: 6}, (_, i) => i * 20);

    return (
        <Container sx={{
            width: '100%',
            p: 3
        }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    mb: 1
                }}
            >
                {title}
            </Typography>

            <ChartContainer>
                <Box sx={{
                    flex: 1,
                    position: 'relative',
                    height: '100%',
                }}>
                    <Box sx={{position: 'absolute', inset: 0}}>
                        {gridLines.map((percentage) => (
                            <GridLine
                                key={percentage}
                                sx={{bottom: `${percentage}%`}}
                            />
                        ))}
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        inset: 0,
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-end',
                        px: 1,
                    }}>
                        {data.map((item) => (
                            <Box
                                key={item.percentage}
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: 30,
                                    height: '100%'
                                }}
                            >


                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: 30,
                                        height: `${item.percentage}%`,
                                        backgroundColor: item.color,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginTop: 1.5,
                                            fontSize: '0.6rem',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            zIndex: 2,
                                        }}
                                    >
                                        {item.percentage}%
                                    </Typography>
                                </Box>
                            </Box>
                        ))}


                    </Box>
                </Box>

                <Box sx={{
                    width: 40,
                    position: 'relative',
                    ml: 0.5
                }}>
                    {gridLines.map((percentage) => (
                        <Typography
                            key={percentage}
                            sx={{
                                position: 'absolute',
                                bottom: `${percentage}%`,
                                left: '5px',
                                fontSize: '0.75rem',
                                transform: 'translateY(50%)'
                            }}
                        >
                            {percentage}%
                        </Typography>
                    ))}
                </Box>
            </ChartContainer>

            <Box sx={{
                mt: 4,
                pt: 1.25,
                borderTop: '1px solid #e0e0e0'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: 1
                }}>
                    {title === "Protocole ARCHI" &&
                        Object.entries(LIST_COLOR_ARCHI).map(([state, config]) => (
                            <Box
                                key={state}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 1.5
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        mr: 0.5,
                                        backgroundColor: config.color
                                    }}
                                />
                                <Typography sx={{fontSize: '0.75rem'}}>
                                    {state}
                                </Typography>
                            </Box>
                        ))}
                    {title === "Protocole DEPERIS" &&
                        Object.entries(LIST_COLOR_DEPERIS).map(([note, config]) => (
                            <Box
                                key={note}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 1.5
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        mr: 0.5,
                                        backgroundColor: config.color
                                    }}
                                />
                                <Typography sx={{fontSize: '0.75rem'}}>
                                    {note}
                                </Typography>
                            </Box>
                        ))}
                </Box>
            </Box>
        </Container>
    );
};

const DeperissementDiagram: React.FC<DiagramProps> = ({data}) => {
    const archiStats = calculateArchiStatistics(data);
    const deperisStats = calculateDeperisStatistics(data);

    return (
        <Box sx={{maxWidth: '4xl', mx: 'auto', width: '100%'}}>
            <BarChart title="Protocole ARCHI" data={archiStats}/>
            <BarChart title="Protocole DEPERIS" data={deperisStats}/>
        </Box>
    );
};

export default DeperissementDiagram;