import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableContainer} from "@mui/material";
import {commonCellStyles, firstCellStyles} from "./StylesTable";
import {headers} from "./INBHelpers";

interface BaseTableProps<T> {
    data: T[];
    renderCell: (item: T, columnIndex: number) => React.ReactNode;
}

const BaseTable = <T extends { essence: string }>({data, renderCell}: BaseTableProps<T>) => {
    return (
        <TableContainer
            sx={{
                maxWidth: {
                    xs: '100%',
                    sm: '600px',
                    md: '900px'
                },
                mx: 'auto',
                px: {
                    xs: 2,
                    sm: 3
                }
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell
                                key={header}
                                align={index === 0 ? 'left' : 'center'}
                                sx={index === 0 ? firstCellStyles : commonCellStyles}
                            >
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item) => (
                        <TableRow key={item.essence}>
                            {[0, 1, 2, 3].map((columnIndex) => (
                                <TableCell
                                    key={columnIndex}
                                    align={columnIndex === 0 ? 'left' : 'center'}
                                    sx={columnIndex === 0 ? firstCellStyles : undefined}
                                >
                                    {renderCell(item, columnIndex)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BaseTable;